import React from 'react'
import styled from 'styled-components'
import fulltimeImg from 'img/fulltime.svg'
import positionImg from 'img/position.svg'
import degreeImg from 'img/degree.svg'
import CustomLink from 'components/shared/CustomLink'
import { FaChevronRight } from 'react-icons/fa'

export default function Job({
  title,
  availability,
  location,
  degree,
  description,
  url = '/',
}) {
  return (
    <Container>
      <Title>{title}</Title>
      <FeaturesContainer className="d-flex flex-wrap">
        {availability && (
          <div className="col-md-4 col-12 d-flex my-1">
            <img alt="" src={fulltimeImg} className="mr-2" /> 
            {' '}
            {availability}
          </div>
        )}

        {location && (
          <div className="col-md-4 col-12 d-flex my-1">
            <img alt="" src={positionImg} className="mr-2" /> 
            {' '}
            {location}
          </div>
        )}
        {degree && (
          <div className="col-md-4 col-12 d-flex my-1">
            <img alt="" src={degreeImg} className="mr-2" /> 
            {' '}
            {degree}
          </div>
        )}
      </FeaturesContainer>
      <Description>{description}</Description>
      <div className="d-flex justify-content-end mt-4">
        <More to={url}>
          <span>Bekijk deze vacature</span>
          <FaChevronRight size={16} className="my-auto ml-2 text-bold" />
        </More>
      </div>
    </Container>
  )
}
const Container = styled.div`
  background: white;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.3);
  padding: 67px 100px 63px 100px;
  margin-bottom: 55px;
  @media screen and (max-width: 992px) {
    padding: 20px;
  }
`
const Title = styled.h3`
  color: ${(props) => props.theme.color.text.main};
  font-size: 42px;
  line-height: 45px;
  @media screen and (max-width: 992px) {
    font-size: 28px;
    line-height: 32px;
  }
`
const FeaturesContainer = styled.div`
  font-size: 16px;
  line-height: 20px;
  margin: 20px 0 27px 0;
  color: ${(props) => props.theme.color.face.secondary};
`
const Description = styled.div`
  color: #818181;
  font-size: 16px;
  line-height: 20px;
`
const More = styled(CustomLink)`
  color: ${(props) => props.theme.color.face.secondary};
  font-size: 18px;
  line-height: 30px;
  text-decoration: underline;
  display: flex;
`
