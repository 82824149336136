/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useState } from 'react'
import styled from 'styled-components'
import { Link, useStaticQuery, graphql } from 'gatsby'

// Images
import Logo from 'img/logo.inline.svg'
import logoBlue from 'img/logo.svg'
import logoWhite from 'img/logo.white.svg'
import homeImg from 'img/home.svg'
import menuBackground from 'img/image-4.webp'

// Components
import CustomLink from 'components/shared/CustomLink'
import Image from 'components/shared/Image'
import ImageBackground from 'components/shared/ImageBackground'

const StyledHeader = styled.nav`
  background-color: ${(props) => props.theme.color.face.light};
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  z-index: 99;

  @media (min-width: 992px) {
    ${props => props.landing ? `
      height: ${props.issticky ? 100 : 100}px;
    ` : `
      height: ${props.issticky ? 130 : 200}px;
    `};
    box-shadow: ${props => props.issticky ? '0 0 30px rgba(0,0,0,0.35)' : 'none'};
  }
  @media screen and (max-width: 991px) {
    height: 90px;
    box-shadow: 0 0 30px rgba(0,0,0,0.35);
  }
`

const Brand = styled(CustomLink)`
  font-size: 0;
`

const MobileBrand = styled(CustomLink)`
  font-size: 0;
  z-index: 999;
`

const BrandLogo = styled(Logo)`
  ${props => props.landing ? `
    height: ${props.issticky ? 65 : 65}px;
  ` : `
    height: ${props.issticky ? 50 : 82}px;
    width: ${props.issticky ? 145 : 216}px;
  `};
`

const NavButton = styled.button`
  bottom: 5px;
  position: relative;
  min-width: 60px;
  min-height: 60px;
  max-width: 60px;
  max-height: 60px;
  text-align: center;
  z-index: 10;
  font-size: 0;
`

const NavButtonStripe = styled.div`
  background-color: ${(props) => props.theme.color.text.secondary};
  height: 2px;
  width: 100%;
  margin: 7px auto;
`

const Header = ({ landing = false, active, issticky, setIsScrollable }) => {
  const { fields } = useStaticQuery(graphql`
    {
      fields: wordpressWpComponenten(wordpress_id: { eq: 97 }) {
        acf {
          menu {
            link {
              title
              url
            }
          }
          menu_bottom {
            link {
              title
              url
            }
          }
          more {
            link {
              title
              url
            }
            socials {
              link {
                title
                target
                url
              }
              icon {
                localFile {
                  publicURL
                }
              }
            }
          }
        }
      }
    }
  `)
  return (
    <StyledHeader landing={landing} issticky={issticky}>
      <MenuMobile landing={landing} fields={fields} active={active} setIsScrollable={setIsScrollable} />
      <MenuDesktop landing={landing} fields={fields} active={active} issticky={issticky} />
    </StyledHeader>
  )
}

const StyledMenuDesktop = styled.div`
  flex: 1;
  align-items: center;
  justify-content: space-around;
  list-style: none;
  margin-bottom: 0;
`
const TopMenuContainer = styled.div`
  background: ${(props) => props.theme.color.face.contrast};
  height: 50px;
`
const TopMenuWrapper = styled.div`
  /* width: 1024px; */
`

const SupportButtonWrapper = styled.div`
  position: relative;
  width: 180px;
`
const SupportButton = styled(Link)`
  background: ${(props) => props.theme.color.face.secondary};
  border-radius: 22px;
  width: 180px;
  text-align: center;
  font-size: 18px;
  margin-top: 8px;
  padding: 1px;
  display: block;
  color: ${(props) => props.theme.color.text.light};
  cursor: pointer;
  font-weight: 700;
  text-decoration: none;
  transition: all 200ms ease-in;
  border: 2px solid ${(props) => props.theme.color.face.secondary};

  &:hover {
    text-decoration: none;
    color: ${(props) => props.theme.color.text.light};
    background: white;
    color: ${(props) => props.theme.color.face.secondary};
  }
`
const ContactButtonIcon = styled(Image)`
  width: 32px;
  height: 32px;
`
const BottomMenuContainer = styled.div`
  ${props => props.landing ? `
    margin: ${!props.issticky ? '17px 0' : '17px 0'};
  ` : `
    margin: ${!props.issticky ? '32px 0' : '10px 0'};
  `};
`
const BottomMenuWrapper = styled.div`
  /* width: 1024px; */
`
const ContactButtons = styled.div`
  ${props => props.landing ? `
    right: 0;
    top: 7px;
  ` : `
    right: -80px;
    top: 0;
  `}
`
const TopMenuLink = styled(CustomLink)`
  font-size: 0;
`

const MenuDesktop = ({ landing, fields, active, issticky }) => (
  <StyledMenuDesktop className="d-none d-lg-block">
    {!landing ? (
      <TopMenuContainer className="d-lg-flex justify-content-center">
        <TopMenuWrapper className="d-none d-lg-flex justify-content-between position-relative container">
          <TopMenuLink to="/" className="my-auto">
            <Image src={homeImg} alt="Home" />
            Home
          </TopMenuLink>
          <Menu items={fields.acf.menu} active={active} />

          <SupportButtonWrapper>
            <SupportButton to={fields.acf.more.link.url}>
              {fields.acf.more.link.title}
            </SupportButton>
          </SupportButtonWrapper>
          <ContactButtons className="position-absolute d-none d-xl-flex h-100 pt-2">
            {fields.acf.more.socials.map(({ link, icon }, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <a className="ml-2" key={index} href={link.url} target={link.target} rel="noopener noreferrer">
                <ContactButtonIcon src={icon} alt={link.title} />
              </a>
            ))}
          </ContactButtons>
        </TopMenuWrapper>
      </TopMenuContainer>
    ) : (
      <div className="position-relative container">
        <ContactButtons landing className="position-absolute d-none d-xl-flex h-100 pt-2">
          {fields.acf.more.socials.map(({ link, icon }, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <a className="ml-2" key={index} href={link.url} target={link.target} rel="noopener noreferrer">
              <ContactButtonIcon src={icon} alt={link.title} />
            </a>
          ))}
        </ContactButtons>
      </div>
    )}
    <BottomMenuContainer landing={landing} issticky={issticky} className="d-lg-flex justify-content-center">
      <BottomMenuWrapper className="d-none d-lg-flex justify-content-between container">
        <Brand className="d-flex align-items-center mr-lg-5" to="/">
          <BrandLogo landing={landing} issticky={issticky} alt="Home" />
          Home
        </Brand>
        {!landing && (
          <BottomMenu items={fields.acf.menu_bottom} active={active} issticky={issticky} />
        )}
      </BottomMenuWrapper>
    </BottomMenuContainer>
  </StyledMenuDesktop>
)

const Item = styled(Link)`
  color: ${(props) =>
    props.active
      ? props.theme.color.face.secondary
      : props.theme.color.text.main};
  margin: auto 0;
  display: block;
  font-size: 16px;
  line-height: 20px;
  font-weight: 300;
  &[aria-current] {
  }
  &:hover {
    text-decoration: none;
    color: ${(props) => props.theme.color.face.secondary};
  }
`

const Menu = ({ items, active }) => (
  <>
    {items.map(({ link: { url, title } }, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <Item key={index} to={url} active={title === active ? 1 : 0}>
        {title}
      </Item>
    ))}
  </>
)
const BottomItem = styled(Link)`
  color: ${(props) =>
    props.active
      ? props.theme.color.face.secondary
      : props.theme.color.text.main};
  margin: auto 0;
  display: block;
  font-weight: 500;
  width: 25%;
  border-left: 2px solid #ed752e;
  padding-left: 20px;
  height: ${props => props.issticky ? 60 : 80}px;
  line-height: ${props => props.issticky ? 60 : 80}px;
  text-decoration: none;
  font-size: ${props => props.issticky ? props.theme.font.size.xm : '25px'};
  &[aria-current] {
  }

  &:hover {
    text-decoration: none;
    color: ${(props) => props.theme.color.face.secondary};
  }
`

const BottomMenu = ({ items, active, issticky }) => {
  return (
    <>
      {items.map(({ link: { url, title } }) => (
        <BottomItem key={title} to={url} active={title === active ? 1 : 0} issticky={issticky}>
          {title}
        </BottomItem>
      ))}
    </>
  )
}

const MenuWrapper = styled.div`
  background-color: ${(props) => props.theme.color.face.main};
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  transition: all 200ms ease-in;
  max-height: ${(props) => (props.isOpen ? '100vh' : 0)};
  overflow: hidden;
`
const MenuBackground = styled(ImageBackground)`
  position: absolute !important;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
`
const MenuBackgroundOverlay = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
  background-color: rgba(0, 0, 0, .7);
`
const MenuBackgroundContent = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 2;
`
const NavButtonClose = styled.div`
  text-align: center;
  z-index: 999;
  font-size: 0;
`

const NavButtonCloseCross = styled.div`
  position: relative;
  width: 32px;
  height: 32px;
  margin-left: 16px;
  transition: all 200ms ease-in;
  &:before,
  &:after {
    background-color: ${(props) => props.theme.color.text.light};
    position: absolute;
    content: ' ';
    height: 33px;
    width: 2px;
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }
`
const MenuMobileHeader = styled.div`
  height: 70px;
  transition: all 200ms ease-in;
`
const MobileDivider = styled.div`
  width: 200px;
  height: 4px;
  background: ${(props) => props.theme.color.face.secondary};
  margin: 0;
`
const MobileMenuIemTop = styled(CustomLink)`
  color: ${(props) => props.theme.color.text.light};
  text-align: left;
  width: 240px;
  margin: 0 auto;
  font-size: 18px;
  font-weight: 300;
  display: block;
`
const MobileMenuIemBottom = styled(CustomLink)`
  color: ${(props) => props.theme.color.text.light};
  text-align: left;
  width: 240px;
  margin: 0 auto;
  font-size: 24px;
  display: block;
  padding: 8px 0 8px 0;
`
const MenuMobileItems = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`

const MenuMobile = ({ fields, active, setIsScrollable }) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <div className="d-block d-lg-none">
      <MenuMobileHeader className="d-flex justify-content-between p-4">
        <Brand to="/">
          <Image src={logoBlue} alt="Home" style={{ objectFit: 'contain', height: 50 }} />
          Home
        </Brand>
        <NavButton 
          role="button" 
          onClick={() => {
            setIsOpen(!isOpen)
            setIsScrollable(false)
          }}
        >
          <NavButtonStripe />
          <NavButtonStripe />
          <NavButtonStripe />
          Menu
        </NavButton>
      </MenuMobileHeader>
      <MenuWrapper data-component="menu-wrapper" className="d-flex flex-column" isOpen={isOpen}>
        <MenuBackground src={menuBackground} alt="Home">
          <MenuBackgroundOverlay />
          <MenuBackgroundContent>
            <MenuMobileHeader className="d-flex justify-content-between p-4">
              <MobileBrand to="/">
                <Image src={logoWhite} alt="Home" />
              </MobileBrand>
              <NavButtonClose 
                role="button" 
                onClick={() => { 
                  setIsOpen(!isOpen)
                  setIsScrollable(true)
                }}
              >
                <NavButtonCloseCross />
                Sluiten
              </NavButtonClose>
            </MenuMobileHeader>

            <MenuMobileItems className="d-flex flex-column justify-content-around py-4">
              <div>
                <div className="pb-4">
                  {fields.acf.menu_bottom.map(({ link: { url, title } }) => {
                    return (
                      <React.Fragment key={title}>
                        <MobileMenuIemBottom to={url} active={title === active}>
                          {title}
                        </MobileMenuIemBottom>
                        {/* {index !== fields.acf.menu_bottom.length - 1 && (
                          <MobileDivider />
                        )} */}
                      </React.Fragment>
                    )
                  })}
                  <MobileDivider className="mt-3" />
                </div>
                
                {fields.acf.menu.map(({ link: { url, title } }) => {
                  return (
                    <React.Fragment key={title}>
                      <MobileMenuIemTop to={url} active={title === active}>
                        {title}
                      </MobileMenuIemTop>
                    </React.Fragment>
                  )
                })}
              </div>
            </MenuMobileItems>
          </MenuBackgroundContent>
        </MenuBackground>
      </MenuWrapper>
    </div>
  )
}

export default Header
