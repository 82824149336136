/* eslint-disable import/no-unresolved */
import React from 'react'
import styled from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'
import Image from 'components/shared/Image'
import { FaLinkedinIn } from 'react-icons/fa'
import ParseContent from 'components/shared/ParseContent'
import { Button } from 'components/elements'

const Container = styled.div`
  background: white;
  border-radius: 20px;
  color: ${(props) => props.theme.color.text.main};
  box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 992px) {
    width: 80%;
    margin: 0 auto;
  }
  
  @media screen and (max-width: 991px) {
    width: 100%;
  }
`
const TopBlock = styled.div`
  width: 100%;
  height: 250px;
  display: flex;
  position: relative;
  z-index: 1;f
  & > div {
    width: 200px;
    height: 200px;
    margin: auto auto 0 auto;
  }
`
const BottomBlock = styled.div`
  padding: 40px;
  box-shadow: 0 -5px 10px -2px rgba(0, 0, 0, 0.3);
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  position: relative;
  z-index: 2;
  flex: 1;
`
const Description = styled(ParseContent)`
  ${props => `
    ${props.theme.headers} {
      font-size: ${props.theme.font.size.xm};
      line-height: ${props.theme.font.size.l};
      margin-bottom: 0;
    }
  `};
`
const Email = styled.a`
  @media (min-width: 992px) {
    font-size: 16px;
    line-height: 30px;
  }

  @media (max-width: 991px) {
    font-size: 12px;
    line-height: 25px;
  }
`
const Linkedin = styled.a`
  display: block;
  background: ${(props) => props.theme.color.face.main};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  svg {
    margin-top: 8px;
  }

  @media (min-width: 992px) {
    min-width: 32px;
    min-height: 32px;
    max-width: 32px;
    max-height: 32px;
  }

  @media (max-width: 991px) {
    min-width: 25px;
    min-height: 25px;
    max-width: 25px;
    max-height: 25px;
  }
`
const Picture = styled(Image)`
  width: 200px;
  height: 200px;
`

export default function Teammate({
  id,
  fields,
  type = 0,
}) {
  const {
    allWordpressWpCta: { edges }
  } = useStaticQuery(graphql`
    {
      allWordpressWpCta {
        edges {
          node {
            wordpress_id
            acf {
              meta {
                description
                image {
                  localFile {
                    childImageSharp {
                      fluid(quality: 100, maxWidth: 250) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                      }
                    }
                  }
                }
              }
              contact {
                email_address
                linkedin_url
                link {
                  title
                  url
                }
              }
            }
          }
        }
      }
    }
  `)

  let data = { ...fields }

  if(id) {
    const { node: teammate } = edges.find(({ node }) => node.wordpress_id === id)

    data = {
      image: teammate.acf.meta.image,
      description: teammate.acf.meta.description,
      email_address: teammate.acf.contact.email_address,
      linkedin_url: teammate.acf.contact.linkedin_url,
      link: teammate.acf.contact.link,
    }
  }

  return (
    <Container type={type}>
      <TopBlock className="d-flex align-items-end justify-content-center">
        <Picture
          src={data.image}
          style={{
            objectFit: 'contain',
          }}
        />
      </TopBlock>
      <BottomBlock>
        <Description content={data.description} />
        <div className="d-flex justify-content-between mt-3">
          <Email href={`mailto:${data.email_address}`}>{data.email_address}</Email>
          <Linkedin href={data.linkedin_url} target="_blank">
            <FaLinkedinIn size={16} color="white" />
          </Linkedin>
        </div>
        {data.link && (
          <div className="d-flex">
            <Button className="mt-4 w-100" width="fluid" to={data.link.url}>
              {data.link.title}
            </Button>
          </div>
        )}
      </BottomBlock>
    </Container>
  )
}
