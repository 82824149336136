/* eslint-disable import/no-named-as-default */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
/* eslint-disable no-console */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import { v4 } from 'uuid'

// Components
import Select from 'react-select'
import {
  Button,
} from 'components/elements'
import Content from './shared/Content'
import Loading from './shared/Loading'

const ConfirmationMessage = styled(Content)`
  font-size: ${(props) => props.theme.font.size.l};
  text-align: center;
`
const Mandatory = styled.span`
  font-size: ${(props) => props.theme.font.size.xm};
  color: ${(props) => props.theme.color.face.contrast};
  margin-left: 5px;
`
const InputContainer = styled.div`
  padding: 0px 0 12px 0;
  width: 100%;
  margin-bottom: 24px;
  @media screen and (max-width: 992x) {
    padding: 0px 0 6px 0;
    margin-bottom: 12px;
  }
`
const Label = styled.div`
  font-size: 16px;
  line-height: 30px;
  color: #6b6b6b;
  @media screen and (max-width: 992x) {
    font-size: 12px;
    line-height: 22px;
  }
`
const Input = styled.input`
  outline: none;
  border: none;
  border-bottom: 1px solid #97979760;
  font-size: 16px;
  line-height: 40px;
  height: 40px;
  width: 100%;
  color: #6b6b6b;

  &.hidden {
    display: none;
  }

  &::placeholder {
    opacity: 0.3;
  }
  @media screen and (max-width: 992px) {
    font-size: 12px;
    line-height: 20px;
  }
`
const Textarea = styled.textarea`
  outline: none;
  border: none;
  border-bottom: 1px solid #97979760;
  font-size: 16px;
  line-height: 40px;
  height: 40px;
  width: 100%;
  color: #6b6b6b;
  height: 100px;
  &::placeholder {
    opacity: 0.3;
  }
  @media screen and (max-width: 992px) {
    font-size: 12px;
    line-height: 20px;
  }
`

const GravityForm = ({ className, id, defaultValues = {},}) => {
  const {
    allGfForm: { edges: gravityData },
  } = useStaticQuery(graphql`
    query {
      allGfForm {
        edges {
          node {
            formId
            slug
            apiURL
            descriptionPlacement
            formFields {
              id
              label
              labelPlacement
              description
              descriptionPlacement
              type
              choices
              content
              errorMessage
              inputMaskValue
              isRequired
              visibility
              cssClass
              placeholder
              size
              defaultValue
              maxLength
            }
            button {
              text
            }
            confirmations {
              message
            }
          }
        }
      }
    }
  `)

  const { node: gfForm } = gravityData.filter(
    ({ node }) => node.formId === id
  )[0]

  const [fields, setFields] = useState({})
  const [form, setForm] = useState(gfForm)
  const [status, setStatus] = useState('')
  const [message, setMessage] = useState('')

  useEffect(() => {
    const tempForm = form

    // add submit button as a field
    if (
      tempForm.formFields.filter((item) => item.type === 'submit').length === 0
    ) {
      tempForm.formFields = [
        ...tempForm.formFields,
        {
          formId: v4(),
          type: 'submit',
          text: tempForm.button.text,
        },
      ]
    }

    setForm({
      ...form,
      ...tempForm,
    })
  }, [])

  async function handleOnSubmit(event) {
    event.preventDefault()

    if (status === 'processing') {
      return
    }

    setStatus('processing')

    try {
      const formData = new FormData()

      Object.keys(defaultValues).forEach((key) => {
        formData.append(key, defaultValues[key])
      })

      for (const [key, value] of Object.entries(fields)) {
        formData.append(key, value)
      }

      const request = await fetch(`${form.apiURL}/submissions`, {
        method: 'POST',
        body: formData,
      })

      const response = await request.json()
      if (response.is_valid === true) {
        setStatus('done')

        setMessage(response.confirmation_message)
      } else {
        setStatus('error')
        console.log(response)
      }
    } catch (error) {
      setStatus('error')
      console.error(error)
    }
  }

  function handleFieldChange(event) {
    // eslint-disable-next-line prefer-destructuring
    let { value } = event.target || event.value

    if (event.target) {
      if (event.target.type === 'checkbox') {
        value = event.target.checked ? event.target.value : ''
      }

      setFields({
        ...fields,
        [event.target.name]: value,
      })
    } else {
      setFields({
        ...fields,
        [event.name]: event.value,
      })
    }
  }

  if (status === 'done') {
    return <ConfirmationMessage content={message} />
  }

  if (form.formFields) {
    return (
      <form
        id={`form_${gfForm.formId}`}
        className={className}
        method="post"
        onSubmit={handleOnSubmit}
      >
        {status === 'processing' && <Loading />}
        {form.formFields &&
          form.formFields.map((field, key) => {
            if(field.visibility === 'hidden') {
              return null
            }

            if (Array.isArray(field)) {
              return (
                <div key={key}>
                  {field.map((item, index) => (
                    <FormField
                      key={index}
                      field={item}
                      fields={fields}
                      onChange={handleFieldChange}
                    />
                  ))}
                </div>
              )
            }

            return (
              <div key={key}>
                <FormField
                  field={field}
                  fields={fields}
                  onChange={handleFieldChange}
                />
              </div>
            )
          })}
      </form>
    )
  }

  console.error('No gravity forms found with id', id)
  return false
}

const TextField = ({
  value,
  onChange,
  field: { id, type, label, labelPlacement, visibility, placeholder, isRequired, cssClass },
}) => (
  <InputContainer>
    {labelPlacement !== 'hidden_label' && (
      <Label htmlFor={`input_${id}`}>
        {label}
        {isRequired && <Mandatory>*</Mandatory>}
      </Label>
    )}
    <Input
      value={value}
      onChange={onChange}
      type="text"
      id={`input_${id}`}
      className={`${type} ${cssClass !== undefined ? cssClass : ''}${visibility} `}
      name={`input_${id}`}
      required={isRequired}
      placeholder={placeholder}
    />
  </InputContainer>
)

const EmailField = ({
  value,
  onChange,
  field: { id, type, label, labelPlacement, placeholder, isRequired, cssClass },
}) => (
  <InputContainer>
    {labelPlacement !== 'hidden_label' && (
      <Label htmlFor={`input_${id}`}>
        {label}
        {isRequired && <Mandatory>*</Mandatory>}
      </Label>
    )}
    <Input
      value={value}
      onChange={onChange}
      type="email"
      id={`input_${id}`}
      className={`${type} ${cssClass !== undefined ? cssClass : ''}`}
      name={`input_${id}`}
      required={isRequired}
      placeholder={placeholder}
    />
  </InputContainer>
)

const PhoneField = ({
  value,
  onChange,
  field: { id, type, label, labelPlacement, placeholder, isRequired, cssClass },
}) => (
  <InputContainer>
    {labelPlacement !== 'hidden_label' && (
      <Label htmlFor={`input_${id}`}>
        {label}
        {isRequired && <Mandatory>*</Mandatory>}
      </Label>
    )}
    <Input
      value={value}
      onChange={onChange}
      type="tel"
      id={`input_${id}`}
      className={`${type} ${cssClass !== undefined ? cssClass : ''}`}
      name={`input_${id}`}
      required={isRequired}
      placeholder={placeholder}
    />
  </InputContainer>
)

const TextAreaField = ({
  value,
  onChange,
  field: { id, type, label, labelPlacement, placeholder, isRequired, cssClass },
}) => (
  <InputContainer>
    {labelPlacement !== 'hidden_label' && (
      <Label htmlFor={`input_${id}`}>
        {label}
        {isRequired && <Mandatory>*</Mandatory>}
      </Label>
    )}
    <Textarea
      value={value}
      onChange={onChange}
      id={`input_${id}`}
      className={`${type} ${cssClass !== undefined ? cssClass : ''}`}
      name={`input_${id}`}
      required={isRequired}
      placeholder={placeholder}
    />
  </InputContainer>
)

const CheckboxField = ({
  value,
  onChange,
  field: { id, type, cssClass, choices },
}) => {
  const list = JSON.parse(choices)

  return (
    <div className="form-group">
      {list.map((checkbox, key) => (
        <div key={key} className="form-group__checkboxes">
          <input
            checked={value}
            onChange={onChange}
            type="checkbox"
            id={`input_${id}_${key + 1}`}
            className={`${type} ${cssClass !== undefined ? cssClass : ''}`}
            name={`input_${id}_${key + 1}`}
            value={checkbox.value}
          />
          <label
            htmlFor={`input_${id}_${key + 1}`}
            className="checkbox-content"
          >
            {checkbox.text}
          </label>
        </div>
      ))}
    </div>
  )
}

const StyledSelect = styled.div``

// Select or Dropdown
const SelectField = ({ field, onChange, value }) => {
  // Populate a options array
  const options = []
  JSON.parse(field.choices).forEach((choice) => {
    options.push({
      value: choice.value,
      label: choice.text,
      type: 'select',
      name: `input_${field.id}`,
    })
  })

  // Custom Select component
  const MySelect = (props) => (
    <Select
      {...props}
      onChange={props.onChange}
      options={props.options}
      placeholder={props.placeholder}
    />
  )

  return (
    <StyledSelect>
      <MySelect
        options={options}
        onChange={onChange}
        value={options.filter((option) => option.value === value)}
        placeholder={field.label}
        classNamePrefix="react-select"
      />
    </StyledSelect>
  )
}

const SubmitButton = ({ field }) => (
  <>
    <div>
      <Button isCustom>
        <button type="submit">{field.text}</button>
      </Button>
    </div>
  </>
)

const FileButton = styled(Button)`
  & button {
    padding: 10px 25px;
    font-size: 16px;
    min-width: 0;
    line-height: initial;
  }
`

const FileField = ({
  value,
  onChange,
  field: { id, label, allowedExtensions },
}) => {
  function handleChange(file) {
    onChange({
      target: {
        name: `input_${id}`,
        value: file
      }
    })
  }

  function handleFileUpload(e) {
    const { target: { files } } = e;

    for (let i = 0; i < files.length; i += 1) {
      handleChange(files[i])
    }
  }

  function handleFileDrop(e) {
    e.preventDefault();

    if (e.dataTransfer.items) {
      // Use DataTransferItemList interface to access the file(s)
      for (let i = 0; i < e.dataTransfer.items.length; i += 1) {
        // If dropped items aren't files, reject them
        if (e.dataTransfer.items[i].kind === 'file') {
          handleChange(e.dataTransfer.items[i].getAsFile());
        }
      }
    } else {
      // Use DataTransfer interface to access the file(s)
      for (let i = 0; i < e.dataTransfer.files.length; i += 1) {
        handleChange(e.dataTransfer.files[i]);
      }
    }
  }

  const ref = useRef(null)

  return (
    <InputContainer
      className="form-upload"
      onDrop={handleFileDrop}
      onDragOver={(e) => e.preventDefault()}
    >
      <Label htmlFor={`input_${id}`}>{label}</Label>
      <input
        ref={ref}
        type="file"
        onChange={handleFileUpload}
        id={`input_${id}`}
        name={`input_${id}`}
        accept={allowedExtensions}
        className="d-none"
      />
      <FileButton isCustom>
        <button
          type="button"
          onClick={() => {
            ref.current.click()
          }}
        >
          {!value ? (
            <span>Bestand kiezen</span>
          ) : (
            value.name
          )}
        </button>
      </FileButton>
    </InputContainer>
  )
}

export const FormField = ({ field, fields, onChange }) => (
  <>
    {field.type === 'text' && (
      <TextField
        onChange={onChange}
        value={fields[`input_${field.id}`]}
        field={field}
      />
    )}
    {field.type === 'email' && (
      <EmailField
        onChange={onChange}
        value={fields[`input_${field.id}`]}
        field={field}
      />
    )}
    {field.type === 'phone' && (
      <PhoneField
        onChange={onChange}
        value={fields[`input_${field.id}`]}
        field={field}
      />
    )}
    {field.type === 'textarea' && (
      <TextAreaField
        onChange={onChange}
        value={fields[`input_${field.id}`]}
        field={field}
      />
    )}
    {field.type === 'checkbox' && (
      <CheckboxField
        onChange={onChange}
        value={fields[`input_${field.id}`]}
        field={field}
      />
    )}
    {field.type === 'select' && (
      <SelectField
        onChange={onChange}
        value={fields[`input_${field.id}`]}
        field={field}
      />
    )}
    {field.type === 'fileupload' && (
      <FileField
        onChange={onChange}
        value={fields[`input_${field.id}`]}
        field={field}
      />
    )}
    {field.type === 'submit' && <SubmitButton field={field} />}
  </>
)

export default GravityForm
