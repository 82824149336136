/* eslint-disable import/no-unresolved */
import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import parse from 'html-react-parser'
import ParseContent from 'components/shared/ParseContent'
// Data
import postInfo from 'src/graphql/postInfo'

// Images
import time from 'img/time.svg'
import place from 'img/place.svg'
import experience from 'img/experience.svg'

const Container = styled.div`
  width: 823px;
  background: white;
  color: ${(props) => props.theme.color.text.main};
  box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.3);
  margin: 20px calc(16.6% - 150px);
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 992px) {
    margin: 20px calc(25% - 150px);
  }
  @media screen and (max-width: 768px) {
    margin: 10px auto;
  }
  & > div:nth-child(2) {
    min-height: 226px;
  }
`
const Content = styled.div`
  padding: 60px 80px;
  font-size: 21px;
  display: flex;
  flex-direction: column;
  text-align: left;
  height: 100%;
  color: ${(props) => props.theme.color.text.main};

  @media screen and (max-width: 991px) {
    padding: 30px 40px;
  }
`
const MoreButton = styled(Link)`
  font-weight: ${props => props.theme.font.weight.l};
  font-size: 18px;
  line-height: 30px;
  text-decoration: underline;
  color: ${(props) => props.theme.color.face.secondary};
`

const Description = styled(ParseContent)`
  p {
    padding-top: 20px;
    font-size: ${props => props.theme.font.size.m};
    line-height: 20px;
    color: ${props => props.theme.color.face.grey};
  }
`

const Title = styled.h2`
  font-size: ${props => props.theme.font.size.xxl};
  font-weight: ${props => props.theme.font.weight.s};
  padding-bottom: 10px;
`

const Info = styled.div`
  color: ${props => props.theme.color.face.secondary};
  font-size: ${props => props.theme.font.size.m};
`

const Vacature = ({
  fields
}) => {
  const postFields = postInfo.get('vacature')

  return (
    <Container>
      <Content>
        <Title>{parse(fields.title)}</Title>
        <Info className="row">
          {fields.acf.meta.time !== 'empty' && (
            <div className="col-sm-3 pb-sm-0 pb-2 d-flex align-items-center">
              <img className="mr-2" src={time} alt="" />
              {fields.acf.meta.time}
            </div>
          )}
          {fields.acf.meta.place !== 'empty' && (
            <div className="col-sm-3 pb-sm-0 pb-2 d-flex align-items-center">
              <img className="mr-2" src={place} alt="" />
              {fields.acf.meta.place}
            </div>
          )}
          {fields.acf.meta.experience !== 'empty' && (
            <div className="col-sm-3 d-flex align-items-center">
              <img className="mr-2" src={experience} alt="" />
              {fields.acf.meta.experience}
            </div>
          )}
        </Info>
        <Description content={fields.acf.meta.description} />
        <div className="d-flex justify-content-end mt-auto">
          <MoreButton to={fields.path}>
            {`${postFields.acf.meta.read_more} >`}
          </MoreButton>
        </div>
      </Content>
    </Container>
  )
}

export default Vacature
