/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

// Libraries
import CookieConsent from 'react-cookie-consent'

// Theme
import Theme from 'styles/Theme'

// Components
import Footer from './Footer'
import Header from './Header'

// CSS
import 'styles/all.scss'

const Content = styled.div`
  @media (min-width: 992px) {
    ${props => props.landing ? `
      padding-top: ${props.issticky ? 100 : 100}px;
    ` : `
      padding-top: ${props.issticky ? 130 : 200}px;
    `};
  }

  @media screen and (max-width: 991px) {
    padding-top: 90px;
  }
`

const CookieLink = styled(Link)`
  color: ${(props) => props.theme.color.text.light};
  text-decoration: underline;
`

const Layout = ({ landing = false, activeHeader, children, footerDescription }) => {
  const [isInitialised, setIsInitialised] = useState(false)
  const [issticky, setSticky] = useState(false)
  const [isScrollable, setIsScrollable] = useState(true)

  const handleScroll = () => {
    setSticky(window.scrollY > 0)
  }

  useEffect(() => {
    if(!isInitialised) {
      setIsInitialised(true)
      handleScroll()
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', () => handleScroll)
    }
  }, [handleScroll])

  return (
    <Theme isScrollable={isScrollable}>
      <Header issticky={issticky} landing={landing} setIsScrollable={setIsScrollable} active={activeHeader} />
      <Content issticky={issticky} landing={landing}>{children}</Content>
      <Footer landing={landing} description={footerDescription} />

      <CookieConsent
        location="bottom"
        style={{ backgroundColor: '#ED752E', color: '#FFFFFF' }}
        buttonText="Akkoord"
      >
        <div className="px-lg-5 font-size-sm">
          <div>
            We gebruiken cookies om ervoor te zorgen dat onze website zo soepel
            mogelijk draait. Als u doorgaat met het gebruiken van de website,
            gaan we er vanuit dat u ermee instemt.
            {' '}
            <CookieLink to="/privacyverklaring">
              Lees hier de privacyverklaring
            </CookieLink>
          </div>
        </div>
      </CookieConsent>
    </Theme>
  )
}

export default Layout
