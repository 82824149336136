/* eslint-disable no-nested-ternary */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import styled, { css } from 'styled-components'
import ButtonShell from './ButtonShell'

const StyledButton = styled.span`
  background-color: transparent;
  display: inline-block;

  & > a,
  & > button {
    display: block;
    border: 2px solid ${(props) => props.theme.color.face.secondary};
    line-height: 32px;
    font-size: 18px;
    font-weight: bold;
    font-weight: 700;
    text-align: center;
    min-width: 180px;

    ${props => props.isRound ? css`
      padding: 8px 25px;
      border-radius: 28px;
    ` : props.width === 'fluid' ? css`
      padding: 12px 0;
      border-radius: 10px;
    ` : css`
      padding: 12px 25px;
      border-radius: 10px;
    `}

    transition: all 200ms ease-in;
    cursor: pointer;
    text-decoration: none;
    background: ${(props) =>
      props.type === 'outline' ? 'white' : props.theme.color.face.secondary};
    color: ${(props) =>
      props.type === 'outline'
        ? props.theme.color.face.secondary
        : props.theme.color.text.light}!important;
    &:hover {
      text-decoration: none;
      color: ${(props) =>
        props.type === 'outline'
          ? 'white'
          : props.theme.color.face.secondary}!important;
      background: ${(props) =>
        props.type === 'outline'
          ? props.theme.color.face.secondary
          : props.theme.color.text.light};
    }
    @media screen and (max-width: 992px) {
      font-size: 14px;
      line-height: 18px;
    }
  }
`

const ButtonDefault = ({
  isAnchor,
  isCustom,
  to,
  children,
  className,
  isRound = 0,
  width = 0,
  type = 'primary',
}) => (
  <StyledButton
    className={className}
    width={width}
    type={type}
    isRound={isRound}
  >
    {isCustom ? (
      children
    ) : (
      <ButtonShell to={to} isAnchor={isAnchor}>
        {children}
      </ButtonShell>
    )}
  </StyledButton>
)

export default ButtonDefault
