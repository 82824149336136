/* eslint-disable import/no-unresolved */
/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import parse from 'html-react-parser'
import Image from 'components/shared/Image'
import ParseContent from 'components/shared/ParseContent'
import Button from 'components/elements/ButtonDefault'
import backImg from 'img/footer.png'
import logo from 'img/logo.white.svg'

const Container = styled.div`
  background: url(${backImg}) no-repeat center;
  background-size: 100% 100%;
  color: ${(props) => props.theme.color.text.light};
  padding: 16px 0 36px 0;
  
  @media screen and (max-width: 991px) {
    background: ${(props) => props.theme.color.face.main};
  }
`

const HeadingTitle = styled.h2`
  @media (min-width: 992px) {
    font-size: ${props => props.theme.font.size.xxl};
  }

  @media (max-width: 991px) {
    font-size: ${props => props.theme.font.size.l};
  }
`

const Footer = ({ landing = false, description }) => {
  const { fields } = useStaticQuery(graphql`
    {
      fields: wordpressWpComponenten(wordpress_id: {eq: 98}) {
        acf {
          top {
            slogan
          }
          menu_1 {
            title
            links {
              link {
                title
                url
              }
            }
          }
          menu_2 {
            title
            links {
              link {
                title
                url
              }
            }
          }
          menu_3 {
            title
            links {
              link {
                title
                url
              }
            }
          }
          info {
            title
            description
            link {
              title
              url
            }
            socials {
              link {
                title
                target
                url
              }
              icon {
                localFile {
                  publicURL
                }
              }
            }
          }
          certificates {
             iso_certificates {
              image {
                localFile {
                  publicURL
                }
              }
            }
          }
          disclaimers {
            link {
              title
              url
            }
          }
        }
      }
    }
  `)

  return (
    <Container>
      <div className="container py-3">
        <HeadingTitle>
          {parse(fields.acf.top.slogan)}
        </HeadingTitle>
        <div className="d-flex justify-content-between mt-4 flex-column flex-lg-row">
          {description && (
            <ParseContent content={description} />
          )}
          {!landing && (
            <>
              <MenuColumn fields={fields.acf.menu_1} />
              <MenuColumn fields={fields.acf.menu_2} />
              <MenuColumn fields={fields.acf.menu_3} />
              <MenuColumn fields={fields.acf.info}>
                <div className="d-flex flex-wrap mt-4">
                  <Button to={fields.acf.info.link.url} isRound className="mr-4">
                    {fields.acf.info.link.title}
                  </Button>
                </div>
              </MenuColumn>
              <MenuColumn fields={fields.acf.certificates} className="d-flex flex-column justify-content-end">
                <div className="d-flex mt-4 mt-lg-0">
                  {fields.acf.info.socials.map(({ link, icon }, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <a key={index} className="ml-2" href={link.url} target={link.target} title={link.title} rel="noopener noreferrer">
                      <SocialIcon src={icon} alt={link.title} />
                    </a>
                  ))}
                </div>
              </MenuColumn>
            </>
          )}
        </div>
      </div>
      <FooterBottom landing={landing} items={fields.acf.disclaimers} />
    </Container>
  )
}

export default Footer

const ColumnHeader = styled.div`
  font-size: 18px;
  line-height: 30px;
  color: white;
  font-weight: 600;
`
const ColumnDivider = styled.div`
  height: 1px;
  background: ${(props) => props.theme.color.face.secondary};
  width: 50px;
  margin: 8px 0;
`
const ColumnItem = styled(Link)`
  display: block;
  font-size: 16px;
  line-height: 30px;
  font-weight: 200;
  color: ${(props) => props.theme.color.text.light};
`
const MenuColumn = ({ fields, children, className }) => {
  return (
    <div className={`mt-2 ${className}`}>
      {fields.title && fields?.title !== '' && (
        <>
          <ColumnHeader>{fields.title}</ColumnHeader>
          <ColumnDivider />
        </>
      )}
      {(fields.links && fields.links.length > 0) && (
        fields.links.map(({ link }) => (
          <ColumnItem key={link.url} to={link.url}>
            {parse(link.title)}
          </ColumnItem>
        ))
      )}
      {fields.description && (
        <ParseContent content={fields.description} />
      )}
      {fields.iso_certificates && fields.iso_certificates.length > 0 && (
        <div className="d-flex mb-4 mt-4 mt-lg-0">
          {fields.iso_certificates.map((item, index) => (
            <IsoImage src={item.image} key={index} />
          ))}
        </div>
      )}
      {children}
    </div>
  )
}

const IsoImage = styled(Image)`
  width: 100px;
  height: auto;
`

const SocialIcon = styled(Image)`
  height: 50px;
  width: 50px;  
`
const BottomMenuContainer = styled.div`
  display: flex;
  width: calc(50% + 565px);
  @media screen and (max-width: 1200px) {
    width: calc(50% + 480px);
  }
  @media screen and (max-width: 992px) {
    width: 100%;
    /* border-bottom: 1px solid ${(props) => props.theme.color.face.secondary}; */
  }
`
const BottomMenu = styled.div`
  padding-bottom: 16px;
  margin-right: 24px;
  border-bottom: 1px solid ${(props) => props.theme.color.face.secondary};
  @media screen and (max-width: 992px) {
    border-bottom: none;
    margin-right: auto;
  }
`
const BottomMenuItem = styled(Link)`
  font-weight: ${props => props.theme.font.weight.s};
  color: white;
  margin-right: 12px;
  padding-right: 12px;
  border-right: 1px solid ${(props) => props.theme.color.face.secondary};
  font-size: 16px;
  line-height: 16px;
  &:last-child {
    border-right: none;
    margin-right: 0;
    padding-right: 0;
  }
  @media screen and (max-width: 992px) {
    border-right: none;
    border-bottom: 1px solid ${(props) => props.theme.color.face.secondary};
    padding-bottom: 8px;
    margin-bottom: 8px;
    width: auto;
    &:last-child {
      border-bottom: none;
    }
  }
`
const Brand = styled(Link)`
  & > svg {
    height: 70px;
  }
`
const FooterBottom = ({ items }) => {
  return (
    <BottomMenuContainer className="mt-5">
      <BottomMenu className="flex-column flex-lg-row d-flex w-100 mb-auto justify-content-end container-md">
        {items.map(({ link }) => {
          return (
            <BottomMenuItem to={link.url} key={link.title}>
              {parse(link.title)}
            </BottomMenuItem>
          )
        })}
      </BottomMenu>
      <Brand className="d-none d-lg-block" to="/">
        <Image src={logo} alt="Home" />
      </Brand>
    </BottomMenuContainer>
  )
}
