/* eslint-disable import/no-unresolved */
import React from 'react'
import styled from 'styled-components'
import ParseContent from 'components/shared/ParseContent'

const Background = styled.div`
  padding: 70px 0 80px 0;
  background: ${(props) => props.theme.color.face.main};
  color: ${props => props.theme.color.text.light};
`

export default function Support({ fields }) {
  return (
    <Background>
      <div className="container d-flex flex-wrap justify-content-between">
        <div className="col-lg-5 col-12">
          <ParseContent isLightHs content={fields.description} />
        </div>
        <div className="col-lg-6 col-12">
          <div>
            <ParseContent
              isLightHs
              content={fields.blok_2}
            />
          </div>
        </div>
      </div>
    </Background>
  )
}