import React from 'react'
import styled from 'styled-components'
import parse from 'html-react-parser'

const Container = styled.div``

const Heading = styled.div`
  font-weight: ${props => props.theme.font.weight.xl};
  color: ${(props) => props.theme.color.text.contrast};
  opacity: 0.5;
  z-index: -1;

  @media (min-width: 1200px) {
    font-size: 140px;
    line-height: 120px;
  }

  @media (max-width: 1199px) {
    font-size: 100px;
    line-height: 120px;
  }

  @media screen and (max-width: 991px) {
    font-size: 80px;
    line-height: 80px;
    margin-left: ${(props) => (props.direction === 'right' ? 'auto' : '')};
    margin-right: ${(props) => (props.direction === 'right' ? '30px' : 'auto')};
  }

  @media screen and (max-width: 576px) {
    font-size: 55px;
    line-height: 55px;
  }
`
const Main = styled.div`
  position: relative;
`

export default ({ heading, direction = 'left', children, className }) => {
  return (
    <Container className={className}>
      {heading && (
        <div className="row m-0 pr-4 pr-lg-0">
          {direction === 'right' && (
            <>
              <div className="col-lg-6 p-0" />
              <div className="col-lg-6 p-0">
                <Heading direction={direction} className="text-left ml-lg-n3 mb-n2 mb-xl-n2 mb-lg-n3">
                  {parse(heading)}
                </Heading>
              </div>
            </>
          )}
          {direction === 'right-right' && (
            <>
              <div className="col-lg-12 p-0">
                <Heading direction={direction} className="text-right ml-lg-n3 mb-n2 mb-xl-n2 mb-lg-n3">
                  {parse(heading)}
                </Heading>
              </div>
            </>
          )}
          {direction === 'left' && (
            <div className="col-lg-6 pl-0">
              <Heading direction={direction} className="text-right mr-lg-n5 mb-n2 mb-xl-n2 mb-lg-n3">
                {parse(heading)}
              </Heading>
            </div>
          )}
          {direction === 'left-left' && (
            <div className="col-lg-6 p-0">
              <Heading direction={direction} className="text-left mr-lg-n5 mb-n2 mb-xl-n2 mb-lg-n3">
                {parse(heading)}
              </Heading>
            </div>
          )}
          {direction === 'left-big' && (
            <div className="col-8 p-0">
              <Heading direction={direction} className="text-right mr-lg-n5 mb-n2 mb-xl-n2 mb-lg-n3">
                {parse(heading)}
              </Heading>
            </div>
          )}
        </div>
      )}
      <Main>{children}</Main>
    </Container>
  )
}
