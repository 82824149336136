/* eslint-disable import/no-unresolved */
import React from 'react'
import styled from 'styled-components'
import { graphql, useStaticQuery , Link } from 'gatsby'
import arrowRight from 'img/arrow-right.svg'
import Image from 'components/shared/Image'
import ParseContent from 'components/shared/ParseContent'
import parse from 'html-react-parser'

const ContentContainer = styled.div`
  position: relative;
  z-index: 3;

  @media (min-width: 992px) {
    min-height: 550px;
  }
`

const MenuContainer = styled.div`
  background: ${props => props.theme.color.face.light};
  box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.5);

  @media (max-width: 992px) {
    max-width: 500px;
    margin: 0 auto;
  }
`

const MenuItem = styled.div`
  display: flex;
  border-top: 2px solid #e9e9e9;
  
  &:last-child {
    border-bottom: 2px solid #e9e9e9;
  }

  & > span {
    margin-left: 18px;
    color: ${props => props.theme.color.text.secondary};
    
    @media (min-width: 992px) {
      font-size: ${props => props.theme.font.size.xm};
    }

    @media (max-width: 991px) {
      font-size: ${props => props.theme.font.size.m};
    }
  }
`

const ImageWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
`

const ImageContainer = styled.div`
  @media (min-width: 992px) {
    max-width: 1500px !important;
  }

  @media (max-width: 991px) {
    padding: 0;
    max-width: 100%;
  }
`

export default function SubItems({ parentId, fields }) {
  const {
    pages
  } = useStaticQuery(graphql`
    {
      pages: allWordpressPage {
        edges {
          node {
            wordpress_id
            parent_element {
              wordpress_id
            }
            acf {
              references {
                sortnumber
              }
            }
            title
            path
          }
        }
      }
    }
  `)
  

  const items = pages.edges
  .filter(({ node }) => node.parent_element && node.parent_element.wordpress_id === parentId)
  .sort((a, b) => a.node.acf.references.sortnumber - b.node.acf.references.sortnumber)

  return (
    <section className="position-relative">
      <ContentContainer className="container">
        <div className="row mt-lg-0 mt-5">
          <div className="col-lg-6 mt-lg-n5">
            <MenuContainer className="p-4 p-lg-5 mt-lg-n5">
              <div className="p-lg-3">
                <ParseContent content={fields.description} />

                {items.map(({ node }) => {
                  return (
                    <MenuItem key={node.wordpress_id} className="py-lg-4 py-3">
                      <Image src={arrowRight} alt="Pijl" />
                      <span>
                        <Link to={node.path}>
                          {parse(node.title)}
                        </Link>
                      </span>
                    </MenuItem>
                  )
                })}
              </div>
            </MenuContainer>
          </div>
        </div>
      </ContentContainer>

      <ImageWrapper className="pt-0 pt-sm-4 pt-lg-5">
        <ImageContainer className="container pt-3 pt-xl-5">
          <div className="row mt-3 mt-xl-0">
            <div className="col-lg-4" />
            <div className="col-lg-8 pt-3">
              <Image
                src={fields.image}
                style={{ width: '100%', maxWidth: 1000, height: 600, objectFit: 'cover' }}
                alt="Allinco"
              />
            </div>
          </div>
        </ImageContainer>
      </ImageWrapper>
    </section>
  )
}