/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import styled, { css } from 'styled-components'
import ParseContent from 'components/shared/ParseContent'

import { BlogFilter } from 'components/shared/BlogContainer'

// Third Party
import parse from 'html-react-parser'

const Container = styled.div`
  background: ${(props) => props.theme.color.face.main};
  color: ${(props) => props.theme.color.text.light};

  ${props => props.vacature ? css`
    padding-bottom: 170px;
  ` : css`
    padding-bottom: 3rem;
  `}
`

const Description = styled(ParseContent)`
  ${props => `
    ${props.theme.headers} {
      margin-bottom: 0;
    }
  `};
`

const FilterButton = styled(BlogFilter)`
  width: 180px;
  line-height: 45px;
  height: 45px;
  border-radius: 10px;
  margin: 10px;
  color: white;
  border: 1px solid white;
  background: transparent;
  font-size: 16px;
  text-align: center;
  cursor: pointer;
  transition: all 200ms ease-in;
  &[data-active="1"] {
    border: 1px solid ${props => props.theme.color.face.secondary};
    background: ${props => props.theme.color.face.secondary};
  }
  &:hover {
    border: ${(props) => `1px solid ${props.theme.color.face.secondary}`};
    background: ${(props) => `${props.theme.color.face.secondary}`};
  }
`

const Filter = ({ fields }) => {
  const {
    categories: {
      nodes: categories
    },
    vacatureTypes: {
      nodes: vacatureTypes
    }
  } = useStaticQuery(graphql`
    {
      categories: allWordpressCategory(filter: {wordpress_id: {ne: 1}}) {
        nodes {
          wordpress_id
          name
        }
      }

      vacatureTypes: allWordpressWpVacatureType {
        nodes {
          wordpress_id
          name
        }
      }
    }
  `)

  return (
    <Container vacature={fields.vacature} className="pt-3">
      <div className="container mt-4">
        <Description isLightHs content={fields.description} />
        <div className="d-flex mt-3 flex-wrap w-100 justify-content-center">
          {fields.vacature ? (
            <>
              {vacatureTypes.map(node => (
                <FilterButton 
                  key={node.wordpress_id}
                  id={node.wordpress_id}
                >
                  {parse(node.name)}
                </FilterButton>
              ))}
            </>
          ) : (
            <>
              {categories.map(node => {
                return (
                  <FilterButton 
                    key={node.wordpress_id}
                    id={node.wordpress_id}
                  >
                    {node.name}
                  </FilterButton>
                )
              })}
            </>
          )}
        </div>
      </div>
    </Container>
  )
}

export default Filter