/* eslint-disable import/no-unresolved */
import React from 'react'
import styled from 'styled-components'
import Image from 'components/shared/Image'
import { useStaticQuery, graphql, Link } from 'gatsby'
import parse from 'html-react-parser'

const Container = styled(Link)`
  border-radius: 30px;
  background: white;
  box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  border: 3px solid white;
  transition: all 300ms ease-in;
  margin: 10px;
  
  &:hover {
    text-decoration: none;
    border: 3px solid #ed752e;
  }
  & > div > *:first-child {
    margin-bottom: 12px;
  }
  & > div > *:last-child {
    color: #ed752e;
    text-align: center;
  }

  @media (min-width: 1200px) {
    width: 125px;
    height: 125px;
  }

  @media (max-width: 1199px) {
    font-size: 14px;
    width: 120px;
    padding: 15px;
    height: 120px;

    & > div > *:last-child {
      line-height: 16px;
    }
  }
  
  @media (max-width: 991px) {
    font-size: 12px;
    width: 100px;
    padding: 10px;
    height: 100px;

    & > div > *:last-child {
      line-height: 13px;
    }
  }
`

const ServicesContainer = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
  flex-wrap: wrap;
  z-index: 1;

  @media (min-width: 992px) {
    margin-top: ${(props) => (props.type === 1 ? '-75px' : '0px')};
  }

  @media screen and (max-width: 991px) {
    position: relative;
    margin-top: ${(props) => (props.type === 1 ? '-25px' : '0px')};
  }
`
const ServiceItemImage = styled(Image)`
  @media (min-width: 1200px) {
    height: 60px;
  }

  @media (max-width: 1199px) {
    height: 55px;
  }

  @media (max-width: 991px) {
    height: 45px;
  }
`

function ServiceItem({ fields }) {
  return (
    <Container to={fields.path} className="d-flex align-items-center justify-content-center">
      <div>
        <ServiceItemImage
          src={fields.acf.media.icon}
          style={{ width: '100%', objectFit: 'contain' }}
          alt={fields.title}
        />
        <div className="mt-xl-2">
          {parse(fields.title)}
        </div>
      </div>
    </Container>
  )
}

export default function ServiceItems({ id, type = 1 }) {
  const {
    allWordpressPage: { edges }
  } = useStaticQuery(graphql`
    {
      allWordpressPage(filter: {parent_element: {wordpress_id: {eq: 124}}}) {
        edges {
          node {
            path
            title
            wordpress_id

            acf {
              media {
                icon {
                  localFile {
                    publicURL
                  }
                }
              }
              references {
                sortnumber
              }
            }
          }
        }
      }
    }
  `)

  const items = edges
  .sort((a, b) => a.node.acf.references.sortnumber - b.node.acf.references.sortnumber)

  return (
    <ServicesContainer type={type}>
      {type === 1 &&
        items.map(({ node }) => {
          return <ServiceItem key={node.wordpress_id} fields={node} />
        })}
      {type === 2 &&
        items.filter(({ node }) => node.wordpress_id !== id).map(({ node }) => {
          return <ServiceItem key={node.wordpress_id} fields={node} />
        })}
    </ServicesContainer>
  )
}
