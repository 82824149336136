import React from 'react'
import styled, { css } from 'styled-components'
import BackgroundImage from 'gatsby-background-image'

const BackgroundImageCSS = css`
  background-size: ${(props) =>
    props.styles && props.styles.backgroundSize
      ? props.styles.backgroundSize
      : 'cover'};
  background-position: ${(props) =>
    props.styles && props.styles.backgroundPosition
      ? props.styles.backgroundPosition
      : 'center'};
  background-repeat: ${(props) =>
    props.styles && props.styles.backgroundRepeat
      ? props.styles.backgroundRepeat
      : 'no-repeat'};
`

const GatsbyBackgroundImage = styled(BackgroundImage)`
  ${BackgroundImageCSS};
`

const Media = ({ className, src, style, children, aboveFold }) => {
  const regular =
    !src.localFile ||
    (src.url && src.url.localFile && src.url.localFile.publicURL) ||
    (src.localFile && src.localFile.publicURL)

  if (regular) {
    let url = ''

    if (typeof src === 'string') {
      url = src
    } else if (src.url && src.url.localFile && src.url.localFile.publicURL) {
      url = src.url.localFile.publicURL
    } else if (src.localFile && src.localFile.publicURL) {
      url = src.localFile.publicURL
    } else {
      url = src.url
    }

    return (
      <GatsbyBackgroundImage
        className={className}
        loading="lazy"
        fluid={{
          src: url,
          srcSet: url,
          srcSetWebp: url,
          srcWebp: url,
          sizes: '',
          aspectRatio: 1
        }}
        styles={style}
        fadeIn={!aboveFold}
      >
        {children}
      </GatsbyBackgroundImage>
    )
  }

  return (
    <GatsbyBackgroundImage
      className={className}
      fluid={src.localFile.childImageSharp.fluid || null}
      styles={style}
      fadeIn={!aboveFold}
      loading="lazy"
    >
      {children}
    </GatsbyBackgroundImage>
  )
}

export default Media
