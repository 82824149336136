/* eslint-disable import/no-unresolved */
import React from 'react'
import styled, { css } from 'styled-components'
import ParseContent from 'components/shared/ParseContent'

const Container = styled.div`
  background: ${(props) => props.theme.color.face.main};
  color: ${(props) => props.theme.color.text.light};
  padding-top: 40px;
  text-align: center;

  @media (min-width: 992px) {
    ${props => props.bigBottom && css`
      padding-bottom: 150px;
    `}
  }

  @media screen and (max-width: 991px) {
    ${props => props.bigBottom && css`
      padding-bottom: 300px;
    `}
  }

  @media screen and (max-width: 536px) {
    ${props => props.bigBottom && css`
      padding-bottom: 350px;
    `}
  }
`

export default function Hero3({ fields, bigBottom }) {
  return (
    <Container bigBottom={bigBottom} className="w-100 d-flex justify-content-center">
      <div className="col-12 col-lg-6">
        <ParseContent
          isLightHs
          content={fields.description}
        />
      </div>
    </Container>
  )
}
