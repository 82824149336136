/* eslint-disable import/no-unresolved */
import React from 'react'
import styled from 'styled-components'
import HeadingLayout from 'components/elements/HeadingLayout'
import Image from 'components/shared/Image'
import ParseContent from 'components/shared/ParseContent'
import GravityForm from 'components/GravityForm'

const Section = styled.section`
  min-height: 725px;
`

const HeadingContainer = styled(HeadingLayout)`
  position: relative;
  z-index: 1;
`

const ContentContainer = styled.div`
  position: relative;
  z-index: 3;
`

const MenuContainer = styled.div`
  color: ${props => props.theme.color.text.secondary};
  background: ${props => props.theme.color.face.light};
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.3);

  @media (max-width: 992px) {
    max-width: 500px;
    margin: 0 auto;
  }
`

const ImageWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
`

const HeadingContainerWrapper = styled.div`
  @media (min-width: 992px) {
    max-width: 1500px !important;
  }
`

const ImageContainer = styled.div`
  @media (min-width: 992px) {
    max-width: 1500px !important;
  }

  @media (max-width: 991px) {
    padding: 0;
    max-width: 100%;
  }
`

export default function Service({
  fields,
  backgroundTitle = '',
  direction = 'left',
  content,
  gravityFormId,
}) {
  {console.log(gravityFormId)}
  return (
    <Section className="position-relative">
      {backgroundTitle !== '' && (
        <HeadingContainerWrapper className="container">
          <HeadingContainer heading={backgroundTitle} direction={direction === 'left' ? 'right' : 'left'} />
        </HeadingContainerWrapper>
      )}

      <ContentContainer className="container">
        <div className="row mt-lg-0 mt-5">
          {direction === 'left' && (
            <>
              <div className={`col-lg-6${backgroundTitle !== '' ? ' mt-lg-n5' : ''}`}>
                <MenuContainer className={`p-4 p-lg-5${backgroundTitle !== '' ? '  mt-lg-n5' : ''}`}>
                  <div className="p-lg-3">
                    {fields.description ? (
                      <ParseContent content={fields.description} />
                    ) : (
                      <GravityForm id={gravityFormId} />
                    )}
                  </div>
                </MenuContainer>
              </div>
            </>
          )}
          {direction === 'right' && (
            <>
              <div className="col-xl-6 col-lg-6" />
              <div className={`col-lg-6 pr-lg-5${backgroundTitle !== '' ? ' mt-lg-n5' : ''}`}>
                <MenuContainer className={`p-4 p-lg-5${backgroundTitle !== '' ? '  mt-lg-n5' : ''}`}>
                  <div className="p-lg-3">
                    <ParseContent content={fields.description} />
                  </div>
                </MenuContainer>
              </div>
            </>
          )}
          {content && (
            <div className="col-lg-12 mt-lg-5 pt-lg-5">
              <div className="pt-lg-3">
                {content}
              </div>
            </div>
          )}
        </div>
      </ContentContainer>

      <ImageWrapper className="pt-0 pt-sm-4 pt-lg-5">
        <ImageContainer className="container pt-3 pt-xl-5">
          <div className="row mt-3 mt-xl-0">
            {direction === 'left' && (
              <>
                <div className="col-lg-4" />
                <div className="col-lg-8 pt-3">
                  <Image
                    src={fields.image}
                    style={{
                      height: '600px',
                      objectFit: 'cover',
                      width: '100%',
                      maxWidth: 1000
                    }}
                    alt="Allinco"
                  />
                </div>
              </>
            )}
            {direction === 'right' && (
              <>
                <div className="col-lg-8 pt-3">
                  <Image
                    src={fields.image}
                    style={{
                      height: '600px',
                      objectFit: 'cover',
                      width: '100%',
                      maxWidth: 1000
                    }}
                    alt="Allinco"
                  />
                </div>
                <div className="col-lg-4" />
              </>
            )}
          </div>
        </ImageContainer>
      </ImageWrapper>
    </Section>
  )
}