/* eslint-disable import/no-unresolved */
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import Swiper from 'react-id-swiper'
import ParseContent from 'components/shared/ParseContent'
import Image from 'components/shared/Image'
import HeadingLayout from 'components/elements/HeadingLayout'
import backImg from 'img/customer-bg.png'
import 'swiper/swiper.scss'

const BoxContainer = styled.div`
  box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.3);
  width: 380px;
  padding: 30px 0px 50px 0px;
  background: white;
  margin-left: calc(50% - 512px);
  color: ${(props) => props.theme.color.text.main};
  position: absolute;
  margin-top: 120px;
  z-index: 1;
  @media screen and (max-width: 992px) {
    width: 280px;
    position: relative;
    margin: -20px auto 0 auto;
    padding: 20px;
    h3 {
      font-size: 24px;
      line-height: 30px;
    }
  }
  h3 {
    font-size: 25px;
    line-height: 25px;
    color: ${(props) => props.theme.color.face.secondary};
  }
  span {
    font-size: 18px;
    line-height: 25px;
    color: ${(props) => props.theme.color.text.main};
  }
`
const BoxImg = styled(Image)`
  width: 200px;
  height: 200px;
  margin: auto auto 0 auto;
`
const BoxDesc = styled.div``
const BoxShadow = styled.div`
  position: absolute;
  width: calc(100% - 80px);
  margin-left: 40px;
  box-shadow: 0 -2px 20px rgba(0, 0, 0, 0.3);
  height: 10px;
  z-index: -1;
  @media screen and (max-width: 992px) {
    margin-left: 10px;
    width: calc(100% - 60px);
  }
`
const BoxContent = styled.div`
  background: white;
  padding: 40px 60px 0 60px;
  @media screen and (max-width: 992px) {
    padding: 20px 20px 0 20px;
  }
`
const MainContainer = styled.div`
  padding: 140px 0 90px 0;
  margin-left: auto;
  width: calc(50% + 300px);
  background: url(${backImg}) no-repeat right;
  background-size: 100% 100%;
  @media screen and (max-width: 992px) {
    background: ${(props) => props.theme.color.face.main};
    width: 100%;
    padding: 40px 0;
  }
`
const SwiperContainer = styled.div`
  width: 580px;
  height: auto;
  margin-left: 300px;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 992px) {
    width: calc(100% - 60px);
    margin: 0 auto;
  }
`
const SwiperContent = styled.div`
  width: 580px;
  height: 100%;
  font-size: 28px;
  text-align: right;
  display: flex;
  flex-direction: column;
  padding: 0 10px;
  color: ${(props) => props.theme.color.text.light};
  & > div:first-child {
    margin-bottom: auto;
    &:before {
      content: '“';
    }
    &:after {
      content: '”';
    }
  }
  & > div:last-child {
    margin-top: auto;
  }
  @media screen and (max-width: 992px) {
    width: 100%;
    font-size: 16px;
  }
`
const BulletsContainer = styled.div`
  margin-top: auto;
  margin-left: auto;
  margin-right: auto;
  background: ${(props) => props.theme.color.face.main};
  height: 30px;
  display: flex;

  @media screen and (max-width: 992px) {
    margin-top: 30px;
    justify-content: space-around;
  }
`
const Bullet = styled.div`
  width: 22px;
  height: 22px;
  border: 1px solid white;
  border-radius: 50%;
  background: ${(props) => (props.active ? 'white' : 'transparent')};
  margin: 0 10px;
  cursor: pointer;
  transition: all 300ms ease-in;
  &:hover {
    background: rgba(255, 255, 255, 0.5);
  }
  &:first-child {
    margin-left: 55px;
  }
  &:last-child {
    margin-right: 55px;
  }
  @media screen and (max-width: 992px) {
    margin: 0 10px;
    width: 16px;
    height: 16px;
  }
`
const Wrapper = styled.div`
  margin-top: 300px;
`
const Container = styled.div`
  display: block;
  @media screen and (max-width: 992px) {
    display: flex;
    flex-direction: column-reverse;
  }
`

export default function Stuff({ title, data }) {
  const params = {
    on: {
      slideChange: () => onSlideChange(),
    },
  }
  const [swiper, setSwiper] = useState(null)
  const [active, setActive] = useState(0)

  const handleClickBullet = (index) => {
    swiper && swiper.slideTo(index)
  }

  const onSlideChange = () => {
    swiper ? setActive(swiper.activeIndex) : {}
  }
  useEffect(() => {
    if (swiper) {
      swiper.on('slideChange', () => onSlideChange())
    }
  }, [swiper])
  return (
    <HeadingLayout heading="Medewerkers">
      <Container>
        <BoxContainer>
          <div className="d-flex justify-content-center">
            <BoxImg src={data[active].img} />
          </div>
          <BoxDesc>
            <BoxShadow />
            <BoxContent>
              <ParseContent content={`<h3>${data[active].name}</h3>`} />

              <span>
                <ParseContent content={data[active].job} />
              </span>
            </BoxContent>
          </BoxDesc>
        </BoxContainer>
        <MainContainer>
          <SwiperContainer>
            <div>
              <Swiper {...params} getSwiper={setSwiper}>
                {data.map((item, index) => {
                  return (
                    <SwiperContent key={item.text}>
                      <div>{item.text}</div>
                    </SwiperContent>
                  )
                })}
              </Swiper>
            </div>
            <BulletsContainer>
              {data.map((item, index) => {
                return (
                  <Bullet
                    key={item.text}
                    active={active === index ? 1 : 0}
                    onClick={() => handleClickBullet(index)}
                  />
                )
              })}
            </BulletsContainer>
          </SwiperContainer>
        </MainContainer>
      </Container>
    </HeadingLayout>
  )
}
