/* eslint-disable import/no-unresolved */
import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import Image from 'components/shared/Image'
import clockImg from 'img/clock.svg'
import parse from 'html-react-parser'

// Data
import postInfo from 'src/graphql/postInfo'

const Container = styled.div`
  width: 300px;
  background: white;
  color: ${(props) => props.theme.color.text.main};
  box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.3);
  margin: 20px calc(16.6% - 150px);
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 992px) {
    margin: 20px calc(25% - 150px);
  }
  @media screen and (max-width: 768px) {
    margin: 10px auto;
  }
  & > div:nth-child(2) {
    min-height: 226px;
  }
`
const Header = styled.div`
  padding: 16px 20px 16px 28px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  & > div:first-child {
    font-size: 25px;
    color: ${(props) => props.theme.color.text.main};
  }
  & > div:last-child {
    font-size: 18px;
    color: ${(props) => props.theme.color.face.secondary};
  }
`
const Content = styled.div`
  padding: 40px 32px;
  font-size: 21px;
  display: flex;
  flex-direction: column;
  text-align: left;
  height: 100%;
  color: ${(props) => props.theme.color.text.main};
`
const MoreButton = styled(Link)`
  font-weight: ${props => props.theme.font.weight.l};
  font-size: 18px;
  line-height: 30px;
  text-decoration: underline;
  color: ${(props) => props.theme.color.face.secondary};
`
export default function Blog({
  fields
}) {
  const blogFields = postInfo.get('blog')

  return (
    <Container>
      <Header>
        <div>{fields.date}</div>
        <div className="d-flex">
          <Image src={clockImg} className="mr-2" alt={fields.acf.meta.readtime} />
          <span>
            {fields.acf.meta.readtime}
            &nbsp;
            {blogFields.acf.meta.read_time}
          </span>
        </div>
      </Header>
      <Image
        src={fields.acf.banner.image}
        style={{
          width: '100%',
          height: 226,
          objectFit: 'cover',
          objectPosition: 'center',
        }}
      />
      <Content>
        <div>{parse(fields.title)}</div>
        <div className="d-flex justify-content-end mt-auto">
          <MoreButton to={fields.path}>
            {blogFields.acf.meta.read_more}
          </MoreButton>
        </div>
      </Content>
    </Container>
  )
}
