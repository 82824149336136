import React from 'react'
import styled from 'styled-components'

export default function Form({ children, noMargin }) {
  return <Container noMargin={noMargin}>{children}</Container>
}

const Container = styled.div`
  width: 100%;
  padding: 70px 60px 60px;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.3);
  background: white;
  color: ${(props) => props.theme.color.text.main};
  position: relative;

  @media (min-width: 992px) {

    ${props => props.noMargin ? `
      margin-top: 0;
    ` : `
      margin-top: -500px;
    `};
    
  }
  
  @media screen and (max-width: 991px) {
    padding: 30px 20px;
    margin-top: -200px;
  }
`
