/* eslint-disable import/no-unresolved */
import React from 'react'
import styled from 'styled-components'
import Image from 'components/shared/Image'
import ImageBackground from 'components/shared/ImageBackground'
import ParseContent from 'components/shared/ParseContent'
import Button from 'components/elements/ButtonDefault'
import mastImg from 'img/hero-mask.png'
import checkImg from 'img/check.svg'
import parse from 'html-react-parser'

const Container = styled.div`
  height: 720px;

  @media screen and (max-width: 992px) {
    display: flex;
    flex-direction: column-reverse;
    height: auto;
  }
`
const MaskContainer = styled(ImageBackground)`
  width: 54%;
  background-position: left;
  background-size: 100% 100%;
  color: white;
  padding-left: calc(50% - 525px);
  padding-top: 60px;
  display: flex;
  flex-direction: column;
  position: absolute !important;

  @media (min-width: 992px) {
    min-height: 660px;
  }

  @media (max-width: 991px) {
    background: ${(props) => props.theme.color.face.main};
    position: relative !important;
    width: 100%;
    padding: 30px 30px 0 30px;
  }
`
const MaskHeading = styled.div`
  width: 400px;
  line-height: 30px;
  font-weight: ${props => props.theme.font.weight.s};

  & b, & strong {
    font-weight: ${props => props.theme.font.weight.m};
  }

  @media (min-width: 992px) {
    font-size: ${props => props.theme.font.size.l} !important;
  }

  @media (max-width: 991px) {
    font-size: ${props => props.theme.font.size.xm};
  }
  
  @media screen and (max-width: 992px) {
    width: 100%;
  }
`

const CheckItemImg = styled(Image)`
  object-size: contain;
  object-position: center;
  width: 30px;
  height: 30px;
  margin-right: 20px;
`

const CheckItemText = styled.div`
  font-weight: ${props => props.theme.font.weight.s};
  line-height: ${props => props.theme.font.size.xl};
  
  & p {
    margin: 0;
  }

  & b, & strong {
    font-weight: ${props => props.theme.font.weight.xl};
  }

  @media (min-width: 992px) {
    font-size: ${props => props.theme.font.size.l};
  }

  @media (max-width: 991px) {
    font-size: ${props => props.theme.font.size.l} !important;
  }

  @media (max-width: 767px) {
    font-size: ${props => props.theme.font.size.xm} !important;
    line-height: ${props => props.theme.font.size.l} !important;
  }
`
const CheckItem = ({ data }) => {
  return (
    <div className="d-flex mb-4">
      <div className="d-flex align-items-center justify-content-center">
        <CheckItemImg src={checkImg} alt="Check" />
      </div>
      <CheckItemText>
        {parse(data)}
      </CheckItemText>
    </div>
  )
}

const BottomBlock = styled.div`
  position: absolute;
  margin-top: -75px;
`
const BottomText = styled.div`
  font-size: 18px;
  line-height: 30px;
  text-align: left;
  margin-bottom: 16px;
`
const MainContainer = styled.div`
  width: 760px;
  margin-left: auto;
  margin-right: calc(50% - 580px);
  display: flex;
  flex-direction: column;
  height: 100%;
  @media screen and (max-width: 992px) {
    width: 100%;
    margin: 0 auto;
  }
`
const MainText = styled.div`
  width: 500px;
  text-align: left;
  margin-left: auto;
  margin-top: 36px;
  font-weight: 700;

  & h1, & h2, & h3 & h4, & h5 {
    @media (min-width: 1200px) {
      font-size: ${props => props.theme.font.size.xxml};
      line-height: ${props => props.theme.font.size.xxml};
    }
    
    @media (max-width: 1199px) {
      font-size: ${props => props.theme.font.size.xxl} !important;
      line-height: ${props => props.theme.font.size.xxl} !important;
    }

    @media (max-width: 767px) {
      font-size: ${props => props.theme.font.size.xl} !important;
      line-height: ${props => props.theme.font.size.xl} !important;
      width: 100% !important;
    }
  }

  @media screen and (max-width: 992px) {
    font-size: 30px;
    width: 100%;
    padding: 0 30px;
    
    & span {
      padding-left: 0 !important;
    }
  }
`

export default function Hero({ fields }) {
  return (
    <Container>
      <MaskContainer
        aboveFold
        src={mastImg}
        style={{ backgroundSize: '100% 100%', backgroundPosition: 'left' }}
        className="pb-5"
      >
        <MaskHeading>
          {parse(fields.description)}
        </MaskHeading>
        {fields.usps.map(({ description }, index) => {
          // eslint-disable-next-line react/no-array-index-key
          return <CheckItem key={index} data={description} />
        })}
        <div className="position-relative mt-lg-auto mt-5 pt-lg-0 pt-5">
          <BottomBlock className="mt-lg-n4">
            <BottomText>
              {fields.cta.title}
            </BottomText>
            <Button target={fields.cta.link.target} to={fields.cta.link.url}>
              {fields.cta.link.title}
            </Button>
          </BottomBlock>
        </div>
      </MaskContainer>
      <MainContainer>
        <MainText>
          <ParseContent className="ml-lg-n5" content={fields.slogan} />
        </MainText>
        <div className="mt-n5 mt-lg-auto">
          <Image
            className="mb-3 mb-lg-n2"
            aboveFold
            src={fields.image}
            style={{
              objectPosition: 'center',
              objectSize: 'contain',
              width: '100%',
            }}
          />
        </div>
      </MainContainer>
    </Container>
  )
}
