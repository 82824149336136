/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import styled from 'styled-components'
import ParseContent from 'components/shared/ParseContent'
import Image from 'components/shared/Image'
import Button from 'components/elements/ButtonDefault'
import GravityForm from 'components/GravityForm'

const Main = styled.div`
  width: 100%;
  background: ${(props) => props.theme.color.face.main};
  color: ${(props) => props.theme.color.text.light};
  font-size: 24px;
  line-height: 35px;
  padding-top: 80px;

  @media (min-width: 992px) {
    height: 500px;
  }
`
const Card = styled.div`
  width: 500px;
  height: auto;
  background: white;
  padding: 70px;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.3);
  position: absolute;
  margin-top: ${props => props.hasForm ? '-175px' : '-50px'};
  margin-left: calc(50% + 50px);
  @media screen and (max-width: 992px) {
    position: static;
    width: 280px;
    margin: 0 auto -50px auto;
    padding: 20px;
    z-index: 1;
  }
`
const Person = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.color.text.main};
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: row;
  & > div:first-child {
    width: 200px;
    @media screen and (max-width: 992px) {
      margin: 0 auto;
    }
  }
  & > div:last-child {
    width: calc(100% - 200px);
    @media screen and (max-width: 992px) {
      margin-top: 20px;
      width: 100%;
    }
  }
  @media screen and (max-width: 992px) {
    flex-direction: column;
  }
`
const PersonDescription = styled(ParseContent)`
  font-size: 18px;
  line-height: 30px;
  margin-bottom: 40px;
  margin-top: 60px;
`
const ImageParseContent = styled(ParseContent)`
  & h3 {
    font-size: ${props => props.theme.font.size.l};
    line-height: ${props => props.theme.font.size.l};
  }
`
const TextBox = styled.div`
  width: 500px;
  margin-left: calc(50% - 500px);
  @media screen and (max-width: 992px) {
    margin-left: 0;
    width: 100%;
    padding: 20px;
  }
`
const Container = styled.div`
  position: relative;
  @media screen and (max-width: 992px) {
    display: flex;
    flex-direction: column;
  }
`

const QuickScan = ({ fields, subject = 'Quickscan' }) => {
  const {
    allWordpressWpCta: { edges }
  } = useStaticQuery(graphql`
    {
      allWordpressWpCta {
        edges {
          node {
            wordpress_id
            acf {
              meta {
                description
                image {
                  localFile {
                    childImageSharp {
                      fluid(quality: 100, maxWidth: 250) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                      }
                    }
                  }
                }
              }
              contact {
                email_address
                linkedin_url
                link {
                  title
                  url
                }
              }
            }
          }
        }
      }
    }
  `)

  let data = { ...fields }

  if(fields.cta && fields.cta.relation.wordpress_id) {
    const { node: teammate } = edges.find(({ node }) => node.wordpress_id === fields.cta.relation.wordpress_id)

    data = {
      image: teammate.acf.meta.image,
      description: teammate.acf.meta.description,
      email_address: teammate.acf.contact.email_address,
      linkedin_url: teammate.acf.contact.linkedin_url,
      link: teammate.acf.contact.link,
    }
  }

  return (
    <Container>
      <Card hasForm={fields.form !== undefined}>
        {fields.form && !!fields.form.gravity_form_id && (
          <>
            <GravityForm id={fields.form.gravity_form_id} defaultValues={{ input_6: subject }} />
          </>
        )}
        {fields.cta && (!fields.form || !fields.form.gravity_form_id) && (
          <>
            <Person>
              <Image
                src={data.image}
                style={{ width: 175, height: 175, objectFit: 'contain' }}
              />
              <div className="pl-4">
                <ImageParseContent content={data.description} />
              </div>
            </Person>
            <PersonDescription content={fields.cta.description} />
            {!fields.cta.withcontact || fields.cta.withcontact === 'Ja' && (
              <div className="d-flex justify-content-center">
                <Button to={data.link.url}>
                  {data.link.title}
                </Button>
              </div>
            )}
          </>
        )}
      </Card>
      <Main>
        <TextBox>
          <ParseContent isLightHs content={fields.description} />
        </TextBox>
      </Main>
    </Container>
  )
}

export default QuickScan