/* eslint-disable import/no-unresolved */
import React from 'react'
import styled, { createGlobalStyle } from 'styled-components'
import ParseContent from 'components/shared/ParseContent'
import Image from 'components/shared/Image'

// Libraries
import Slider from 'react-slick'

// Media
import chevron from 'img/arrow-circle-right.svg'

const SlickStyling = createGlobalStyle`
  /* Slider */
  .slick-slider
  {
      position: relative;
  
      display: block;
      box-sizing: border-box;
  
      -webkit-user-select: none;
         -moz-user-select: none;
          -ms-user-select: none;
              user-select: none;
  
      -webkit-touch-callout: none;
      -khtml-user-select: none;
      -ms-touch-action: pan-y;
          touch-action: pan-y;
      -webkit-tap-highlight-color: transparent;
  }
  
  .slick-list
  {
      position: relative;
  
      display: block;
      overflow: hidden;
  
      margin: 0;
      padding: 0;
  }
  .slick-list:focus
  {
      outline: none;
  }
  .slick-list.dragging
  {
      cursor: pointer;
      cursor: hand;
  }
  
  .slick-slider .slick-track,
  .slick-slider .slick-list
  {
      -webkit-transform: translate3d(0, 0, 0);
         -moz-transform: translate3d(0, 0, 0);
          -ms-transform: translate3d(0, 0, 0);
           -o-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0);
  }
  
  .slick-track
  {
      position: relative;
      top: 0;
      left: 0;
  
      display: block;
      margin-left: auto;
      margin-right: auto;
  }
  .slick-track:before,
  .slick-track:after
  {
      display: table;
  
      content: '';
  }
  .slick-track:after
  {
      clear: both;
  }
  .slick-loading .slick-track
  {
      visibility: hidden;
  }
  
  .slick-slide
  {
      display: none;
      float: left;
  
      height: 100%;
      min-height: 1px;
  }
  [dir='rtl'] .slick-slide
  {
      float: right;
  }
  .slick-slide img
  {
      display: block;
  }
  .slick-slide.slick-loading img
  {
      display: none;
  }
  .slick-slide.dragging img
  {
      pointer-events: none;
  }
  .slick-initialized .slick-slide
  {
      display: block;
  }
  .slick-loading .slick-slide
  {
      visibility: hidden;
  }
  .slick-vertical .slick-slide
  {
      display: block;
  
      height: auto;
  
      border: 1px solid transparent;
  }
  .slick-arrow.slick-hidden {
      display: none;
  }
  
  /* Arrows */
  .slick-prev,
  .slick-next
  {
      font-size: 0;
      line-height: 0;
  
      position: absolute;
      top: 50%;
  
      display: block;
  
      width: 20px;
      height: 20px;
      padding: 0;
      -webkit-transform: translate(0, -50%);
      -ms-transform: translate(0, -50%);
      transform: translate(0, -50%);
  
      cursor: pointer;
  
      color: transparent;
      border: none;
      outline: none;
      background: transparent;
  }
  .slick-prev:hover,
  .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus
  {
      color: transparent;
      outline: none;
      background: transparent;
  }
  .slick-prev:hover:before,
  .slick-prev:focus:before,
  .slick-next:hover:before,
  .slick-next:focus:before
  {
      opacity: 1;
  }
  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before
  {
      opacity: .25;
  }
  
  .slick-prev:before,
  .slick-next:before
  {
      content: "";
      display: block;

      background-image: url(${chevron});
      background-repeat: no-repeat;
      background-size: contain;
  
      opacity: .75;
      width: 40px;
      height: 40px;
  
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
  }
  
  .slick-prev
  {
      left: -25px;
      transform: rotate(180deg);
      top: 50% !important;
      margin-top: -10px;
  }
  
  [dir='rtl'] .slick-prev
  {
      right: -25px;
      left: auto;
  }

  
  .slick-next
  {
      right: -25px;
      top: 50% !important;
      margin-top: -20px;
  }
  [dir='rtl'] .slick-next
  {
      right: auto;
      left: -25px;
  }
  
  @media (max-width: 767.98px) {
    .slick-prev {
      left: 0;
    }
    
    .slick-next {
      right: 0;
    }
  }
`

const Container = styled.div`
  width: 680px;
  margin: 0 auto 0 auto;
  color: ${(props) => props.theme.color.text.main};

  @media screen and (max-width: 992px) {
    width: auto;
    margin: 80px 30px 0 30px;
  }
`

const Slide = styled.div`
  outline: none;
`

const Logo = styled.div`
  outline: none;
  width: 100%;
  min-height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  
  & > div {
    margin: 0 auto;
    max-width: 140px;
    width: 100%;
    height: 100%;
  }
`

const Content = styled(ParseContent)`
  font-size: ${props => props.theme.font.size.sm};
`

export default function Prides({ fields }) {
  return (
    <Container>
      <SlickStyling />
      <ParseContent content={fields.description} />
      <Slider
        dots={false}
        infinite
        slidesToShow={fields.items.length > 3 ? 3 : fields.items.length}
        slidesToScroll={3}
        speed={350}
        draggable={false}
        responsive={[
          {
            breakpoint: 996,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
            },
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
            },
          },
          {
            breakpoint: 400,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          }
        ]}
      >
        {fields.items.map((item, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Slide key={index}>
            <Logo>
              <Image src={item.image} alt="" />
            </Logo>
            <div className="pt-3 px-4">
              <Content content={item.description} />
            </div>
          </Slide>
        ))}
      </Slider>
    </Container>
  )
}