/* eslint-disable import/no-unresolved */
import React, { useState } from 'react'
import styled from 'styled-components'
import ParseContent from 'components/shared/ParseContent'
import { motion } from 'framer-motion'
import arrowImg from 'img/arrow-circle-right.svg'

const Container = styled.div`
  margin: 0 auto;
  width: 700px;
  color: ${(props) => props.theme.color.text.main};

  @media screen and (max-width: 992px) {
    margin: 0 30px;
    width: auto;
  }
`
const ContainerDescription = styled(ParseContent)`
  ${props => `
    ${props.theme.headers} {
      font-size: ${props.theme.font.size.l};
    }
  `};
`
const Box = styled.div`
  box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.3);
  background: white;
  padding: 5px 80px;
  margin-top: 32px;

  @media screen and (max-width: 992px) {
    padding: 5px 20px;
    box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.3);
  }
`
const Item = styled.div`
  width: 100%;
  padding: 38px 0;
  border-bottom: 1px solid ${(props) => props.theme.color.text.main};
  &:last-child {
    border-bottom: none;
  }
  .color {
    color: ${(props) => props.theme.color.face.secondary};
  }
  @media screen and (max-width: 992px) {
    padding: 24px 0;
    font-size: 20px;
    img {
      width: 36px;
      height: 36px;
      margin: auto 0 auto auto;
    }
  }
`
const Title = styled(motion.div)`
  &:hover {
    text-decoration: underline;
  }

  ${props => `
    ${props.theme.headers} {
      font-size: ${props.theme.font.size.l};
      margin-bottom: 0;
    }
  `};
`
const Description = styled(motion.div)`
  overflow: hidden;
`

export default function NameStands({ fields }) {
  const [indexOpen, setIndexOpen] = useState(null)

  const setOpen = index => {
    setIndexOpen(index === indexOpen ? null : index)
  }

  return (
    <Container>
      <div>
        <ContainerDescription content={fields.description} />
      </div>
      <Box>
        {fields.points.map(({ title, description }, index) => {
          const status = indexOpen === index ? 'open' : 'closed'

          return (
            // eslint-disable-next-line react/no-array-index-key
            <Item key={index}>
              <Title
                role="button"
                className="d-flex align-items-center justify-content-between"
                onClick={() => {
                  setOpen(index)
                }}
              >
                <ParseContent content={title} />
                <motion.div
                  initial="open"
                  variants={{
                    open: { rotate: 90 },
                    closed: { rotate: 0 },
                  }}
                  animate={status}
                >
                  <img src={arrowImg} alt="Open" />
                </motion.div>
              </Title>
              <Description
                initial="open"
                variants={{
                  open: { opacity: 1, height: 'auto' },
                  closed: { opacity: 0, height: 0 },
                }}
                animate={status}
              >
                <div className="mt-3">
                  <ParseContent content={description} />
                </div>
              </Description>
            </Item>
          )
        })}
      </Box>
    </Container>
  )
}
