/* eslint-disable import/no-unresolved */
import { graphql, useStaticQuery } from 'gatsby'

const postInfo = {
  get(type) {
    const { 
      blog,
      vacature,
      klantverhalen
     } = useStaticQuery(graphql`
      {
        blog: wordpressPage(wordpress_id: {eq: 120}) {
          path

          acf {
            meta {
              read_more
              read_time
              show_more_button
              show_less_button
              back_button
              more_posts
              more_posts_background_title
            }
          }
        }

        vacature: wordpressPage(wordpress_id: {eq: 122}) {
          path

          acf {
            meta {
              read_more
              show_more_button
              show_less_button
              back_button
              more_posts
              more_posts_background_title
            }
          }
        }

        klantverhalen: wordpressPage(wordpress_id: {eq: 116}) {
          path

          acf {
            meta {
              read_more
              show_more_button
              show_less_button
              back_button
              more_posts
              more_posts_background_title
            }
          }
        }
      }
    `)
  
    return {
      'blog': blog,
      'vacature': vacature,
      'klantverhalen': klantverhalen,
    }[type]
  }
}

export default postInfo