import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  padding: 0px 0 12px 0;
  width: 100%;
  margin-bottom: 24px;
  @media screen and (max-width: 992x) {
    padding: 0px 0 8px 0;
  }
`
const Label = styled.div`
  font-size: 16px;
  line-height: 30px;
  color: #6b6b6b;
  @media screen and (max-width: 992x) {
    font-size: 12px;
    line-height: 22px;
  }
`
const InputContainer = styled.textarea`
  outline: none;
  border: none;
  border-bottom: 1px solid #97979760;
  font-size: 16px;
  line-height: 40px;
  height: 40px;
  width: 100%;
  color: #6b6b6b;
  height: 100px;
  &::placeholder {
    opacity: 0.3;
  }
  @media screen and (max-width: 992px) {
    font-size: 12px;
    line-height: 20px;
  }
`
export default function TextArea({ value, onChange, label, placeholder }) {
  return (
    <Container>
      <Label> 
        {' '}
        {label}
        {' '}
      </Label>
      <InputContainer
        value={value}
        placeholder={placeholder}
        onChange={onChange}
      />
    </Container>
  )
}
