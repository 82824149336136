/* eslint-disable import/no-unresolved */
import React from 'react'
import styled from 'styled-components'
import Image from 'components/shared/Image'
import Button from 'components/elements/ButtonDefault'
import ParseContent from 'components/shared/ParseContent'

const Container = styled.div`
  background: white;
  border-radius: 20px;
  color: ${(props) => props.theme.color.text.main};
  box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 992px) {
    width: 90%;
    margin: 0 auto;
  }
  
  @media screen and (max-width: 991px) {
    width: 100%;
  }
`
const TopBlock = styled.div`
  width: 100%;
  height: 250px;
  display: flex;
  & > div {
    width: 100%;
    height: 100%;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }
`
const BottomBlock = styled.div`
  padding: 20px 60px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;

  @media screen and (max-width: 992px) {
    padding: 20px;
  }
`
const DescBlock = styled.div`
  /* display: flex; */
  & > div:last-child {
    min-width: 245px;
    margin-left: 35px;
    @media screen and (max-width: 992px) {
      width: 100%;
      margin-left: 0;
      margin-top: 30px;
      display: flex;
      justify-content: center;
    }
  }
  @media screen and (max-width: 992px) {
    display: block;
  }
`
const Description = styled(ParseContent)`
  ${props => `
    ${props.theme.headers} {
      font-size: ${props.theme.font.size.l};
      margin-bottom: 15px;
    }
  `};
`

export default function Working({
  fields,
  className
}) {
  return (
    <Container className={className}>
      <TopBlock>
        <Image
          src={fields.image}
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
          }}
        />
      </TopBlock>
      <BottomBlock className="row">
        <Description className="col-lg-6" content={fields.description} />
        <DescBlock className="col-lg-6 d-flex align-items-center">
          <Button to={fields.link.url} className="my-auto pt-3">
            {fields.link.title}
          </Button>
        </DescBlock>
      </BottomBlock>
    </Container>
  )
}
