import React from 'react'
import { ThemeProvider, createGlobalStyle } from 'styled-components'

const mainTheme = {
  color: {
    face: {
      main: '#201C3F',
      contrast: '#E3E3E3',
      secondary: '#ED752E',
      light: '#FFFFFF',
      grey: '#818181'
    },

    text: {
      main: '#201C3F',
      contrast: '#E3E3E3',
      secondary: '#606060',
      light: '#FFFFFF',
    },
  },

  font: {
    family: {
      main: 'Ubuntu',
      secondary: 'Verdana, Arial',
    },

    size: {
      xxs: '8px',
      xs: '10px',
      s: '12px',
      sm: '14px',
      m: '16px',
      xm: '20px',
      l: '24px',
      30: '30px',
      xl: '32px',
      35: '35px',
      42: '42px',
      xxl: '45px',
      xxml: '60px',
      xxxl: '70px',
      75: '75px',
    },

    weight: {
      s: 300,
      m: 400,
      xm: 500,
      l: 600,
      xl: 700,
    },
  },
  headers: '& h1, & h2, & h3, & h4, & h5'
}

const GlobalStyle = createGlobalStyle`
  html, body {
    height: 100%;
    overflow: ${props => props.isScrollable ? 'initial' : 'hidden'};
  }

  body {
    font-family: ${(props) => props.theme.font.family.main};
    font-size: ${(props) => props.theme.font.size.m};
    color: ${(props) => props.theme.color.text.main};
  }

  ${(props) =>
    Object.keys(props.theme.color.face).map((key) => {
      return `.color-background-${key} { background-color: ${props.theme.color.face[key]}; }`
    })}

  ${(props) =>
    Object.keys(props.theme.color.text).map((key) => {
      return `.color-${key} { color: ${props.theme.color.text[key]}; }`
    })}

  ${(props) =>
    Object.keys(props.theme.font.family).map((key) => {
      return `.font-family-${key} { font-family: ${props.theme.font.family[key]}; }`
    })}

  ${(props) =>
    Object.keys(props.theme.font.size).map((key) => {
      return `.font-size-${key} { font-size: ${props.theme.font.size[key]}; }`
    })}

  ${(props) =>
    Object.keys(props.theme.font.weight).map((key) => {
      return `.font-weight-${key} { font-weight: ${props.theme.font.weight[key]}; }`
    })}

  a {
    color: inherit;

    &:hover {
      color: inherit;
      text-decoration: underline;
    }
  }

  #rcc-confirm-button {
    background-color: ${props => props.theme.color.face.secondary} !important;
    color: ${props => props.theme.color.text.light} !important;
    text-decoration: underline !important;
  }

  .CookieConsent {
    box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.5);
  }

  @media (min-width: 1200px) {
    .container {
      max-width: 1180px;
    }
  }
`

const Theme = ({ children, isScrollable }) => (
  <ThemeProvider theme={mainTheme}>
    <GlobalStyle isScrollable={isScrollable} />
    {children}
  </ThemeProvider>
)

export default Theme
