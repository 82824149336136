/* eslint-disable import/no-unresolved */
import React from 'react'
import styled from 'styled-components'
import Image from 'components/shared/Image'
import ImageBackground from 'components/shared/ImageBackground'
import ParseContent from 'components/shared/ParseContent'

// Elements
import ButtonDefault from 'components/elements/ButtonDefault'

// Data
import postInfo from 'src/graphql/postInfo'

// Images
import maskImg from 'img/hero-mask2.png'
import clockImg from 'img/clock.svg'
import time from 'img/time.svg'
import place from 'img/place.svg'
import experience from 'img/experience.svg'

const Container = styled.div`
  display: flex;
  flex-direction: column;

  & > div:first-child {
    @media (min-width: 992px) {
      height: 380px;
      position: absolute;
      width: 52%;
      margin-left: 48%;
    }

    @media (max-width: 991px) {
      height: 250px;
    }
  }
  
  @media (min-width: 992px) {
    height: 380px;
  }
`
const MaskContainer = styled(ImageBackground)`
  background-position: left;
  background-size: 100% 100%;
  color: white;
  padding: 25px 6% 25px calc(50% - 512px);
  display: flex;
  flex-direction: column;
  position: absolute !important;

  @media (min-width: 992px) {
    height: 380px;
    width: 54%;
  }

  @media (max-width: 991px) {
    background: ${(props) => props.theme.color.face.main};
    position: relative !important;
    width: 100%;
    padding: 20px 30px 0 20px;
  }
`
const Heading = styled.div`
  width: auto;
  font-size: 40px;
  line-height: 66px;
  @media screen and (max-width: 992px) {
    width: 100%;
    font-size: 30px;
    line-height: 50px;
  }
  .color {
    color: ${(props) => props.theme.color.face.secondary};
  }
`
const Date = styled.div`
  font-size: 25px;
  color: ${(props) => props.theme.color.text.light};
  margin-left: auto;
  @media screen and (max-width: 992px) {
    font-size: 20px;
  }
`
const Time = styled.div`
  color: ${(props) => props.theme.color.face.secondary};
  font-size: 18px;
  line-height: 45px;
  margin-bottom: 25px;
  img {
    margin-right: 12px;
  }
  @media screen and (max-width: 992px) {
    font-size: 14px;
  }
`

const VacancyInfo = styled.div`
  color: ${props => props.theme.color.face.secondary};
  font-weight: ${props => props.theme.font.weight.xl};
  font-size: ${props => props.theme.font.size.xm};
  
  img {
    
  }
`

const ApplyButton = styled.div``

export default function Hero4({ fields }) {
  const blogFields = postInfo.get('blog')

  return (
    <Container>
      <div className="order-3 order-lg-1">
        <Image
          src={fields.image}
          style={{
            objectFit: 'cover',
            width: '100%',
            height: '100%',
            backgroundPosition: 'right',
          }}
          aboveFold
        />
      </div>
      <MaskContainer
        src={maskImg}
        style={{ backgroundSize: '100% 100%', backgroundPosition: 'left' }}
        aboveFold
        className="order-2"
      >
        {fields.date && (
          <Date>
            {fields.date}
          </Date>
        )}
        <Heading>
          <ParseContent isLightHs content={fields.description} />
        </Heading>

        {fields.time && (
          <VacancyInfo className="row py-2">
            <div className="col-1 d-flex justify-content-center align-items-center">
              <img src={time} alt="" /> 
            </div>
            <div className="col-8 col-md-11">
              {fields.time}
            </div>
          </VacancyInfo>
        )}

        {fields.place && (
          <VacancyInfo className="row py-2">
            <div className="col-1 d-flex justify-content-center align-items-center">
              <img src={place} alt="" /> 
            </div>
            <div className="col-8 col-md-11">
              {fields.place}
            </div>
          </VacancyInfo>
        )}

        {fields.experience && (
          <VacancyInfo className="row py-2">
            <div className="col-1 d-flex justify-content-center align-items-center">
              <img src={experience} alt="" /> 
            </div>
            <div className="col-8 col-md-11">
              {fields.experience}
            </div>
          </VacancyInfo>
        )}

        {fields.path && (
          <ApplyButton className="row py-2">
            <ButtonDefault to={`/solliciteren?functie=${fields.title}`}>Solliciteren</ButtonDefault>
          </ApplyButton>
        )}

        {fields.readtime && (
          <Time className="d-flex mt-auto">
            <img src={clockImg} alt="Leestijd" /> 
            {' '}
            {fields.readtime}
            &nbsp;
            {blogFields.acf.meta.read_time}
          </Time>
        )}

      </MaskContainer>
    </Container>
  )
}
