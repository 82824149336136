/* eslint-disable import/no-unresolved */
import React, { useState, useEffect, useRef } from 'react'
import Img from 'gatsby-image'

// Third Party
import styled from 'styled-components'
import Swiper from 'react-id-swiper'

// Components
import ParseContent from 'components/shared/ParseContent'
import HeadingLayout from 'components/elements/HeadingLayout'
import backImg from 'img/customer-bg.png'
import 'swiper/swiper.scss'

const BoxContainer = styled.div`
  box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.3);
  width: 380px;
  padding: 40px 90px 40px 90px;
  background: white;
  margin-left: calc(50% - 512px);
  color: ${(props) => props.theme.color.text.main};
  position: absolute;
  margin-top: 70px;
  z-index: 1;
  h3 {
    font-size: 42px;
    line-height: 45px;
    margin-bottom: 54px;
  }
  @media screen and (max-width: 992px) {
    width: 280px;
    position: relative;
    margin: -20px auto 0 auto;
    padding: 20px 40px 20px 40px;
    h3 {
      font-size: 24px;
      line-height: 30px;
      margin-bottom: 24px;
    }
  }
`

const MainContainer = styled.div`
  padding: 150px 0 170px 0;
  margin-left: auto;
  width: calc(50% + 300px);
  background: url(${backImg}) no-repeat right;
  background-size: 100% 100%;
  @media screen and (max-width: 992px) {
    background: ${(props) => props.theme.color.face.main};
    width: 100%;
    padding: 40px 0;
  }
`

const SwiperContainer = styled.div`
  width: 580px;
  height: auto;
  margin-left: 300px;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 992px) {
    width: calc(100% - 60px);
    margin: 0 auto;
  }
`
const SwiperContent = styled.div`
  width: 580px;
  height: 100%;
  font-size: 28px;
  text-align: right;
  display: flex;
  flex-direction: column;
  padding: 0 10px;
  color: ${(props) => props.theme.color.text.light};
  & > div:first-child {
    margin-bottom: auto;
  }
  & > div:last-child {
    margin-top: auto;
  }
  @media screen and (max-width: 992px) {
    width: 100%;
    font-size: 16px;
  }

  & h1, & h2, & h3, & h4, & h5 {
    @media (min-width: 992px) {
      font-size: ${props => props.theme.font.size.xl};
      line-height: ${props => props.theme.font.size.xl};
    }

    @media (max-width: 991px) {
      font-size: ${props => props.theme.font.size.l};
      line-height: ${props => props.theme.font.size.l};
    }
  }
`

const BulletsContainer = styled.div`
  margin-top: auto;
  margin-left: auto;
  margin-right: auto;
  background: ${(props) => props.theme.color.face.main};
  height: 30px;
  display: flex;
  position: absolute;
  bottom: 65px;
  right: 250px;

  @media screen and (max-width: 992px) {
    margin-top: 30px;
    justify-content: space-around;
    display: none;
  }
`

const Bullet = styled.div`
  width: 22px;
  height: 22px;
  border: 1px solid white;
  border-radius: 50%;
  background: ${(props) => (props.active ? 'white' : 'transparent')};
  margin: 0 10px;
  cursor: pointer;
  transition: all 300ms ease-in;
  &:hover {
    background: rgba(255, 255, 255, 0.5);
  }
  &:first-child {
    margin-left: 55px;
  }
  &:last-child {
    margin-right: 55px;
  }
  @media screen and (max-width: 992px) {
    margin: 0 10px;
    width: 16px;
    height: 16px;
  }
`

const Container = styled.div`
  display: block;
  @media screen and (max-width: 992px) {
    display: flex;
    flex-direction: column-reverse;
  }
`

const Content = styled(ParseContent)`
  h3 {
    margin-bottom: 0;
    font-size: ${props => props.theme.font.size.l};
  }

  p {
    margin-top: -8px;
  }
`

const SecondContent = styled(ParseContent)`
  p {
    text-align: left;
    font-weight: 400;
    font-size: ${props => props.theme.font.size['30']};
    line-height: 35px;
  
    @media screen and (max-width: 991px) {
      font-size: ${props => props.theme.font.size.xm};
      line-height: 25px;
    }
  }
`

const StyledImg = styled(Img)`
  height: 200px;
  width: 200px;
`

export default function Slider({ fields }) {

  const slides = fields.relation

  const [active, setActive] = useState(0)

  const ref = useRef(null);

  const handleClickBullet = (index) => {
    if (ref.current !== null && ref.current.swiper !== null) {
      
      ref.current.swiper.slideTo(index)
    }
  }

  const onSlideChange = () => {
    if (ref.current !== null && ref.current.swiper !== null) {
      setActive(ref.current.swiper.activeIndex)
    }
  }

  const params = {
    on: {
      slideChange: () => onSlideChange(),
    },
  }

  useEffect(() => {
    if (ref.current !== null && ref.current.swiper !== null) {
      ref.current.swiper.on('slideChange', () => onSlideChange())
    }
  }, [])

  return (
    <HeadingLayout direction='left-big' heading={fields.background_title}>
      <Container>
        <BoxContainer>
          <StyledImg fluid={slides[active].acf.meta.image.localFile.childImageSharp.fluid} alt="" />
          <Content className="pt-4" content={slides[active].acf.meta.description} />
        </BoxContainer>
        <MainContainer>
          <SwiperContainer>
            <div>
              <Swiper on={params.on} ref={ref}>
                {slides.map(node => {
                  return (
                    <SwiperContent key={node.wordpress_id}>
                      <SecondContent isLightHs content={node.acf.meta.quote_description} />
                    </SwiperContent>
                  )
                })}
              </Swiper>
            </div>
            <BulletsContainer>
              {slides.map((node, index) => {
                return (
                  <Bullet
                    key={node.wordpress_id}
                    active={active === index ? 1 : 0}
                    onClick={() => handleClickBullet(index)}
                  />
                )
              })}
            </BulletsContainer>
          </SwiperContainer>
        </MainContainer>
      </Container>
    </HeadingLayout>
  )
}
