/* eslint-disable import/no-unresolved */
import React from 'react'
import styled from 'styled-components'

import Image from 'components/shared/Image'
import ParseContent from 'components/shared/ParseContent'
import Button from 'components/elements/ButtonDefault'

const BoxContainer = styled.div`
  width: 500px;
  padding: 60px 70px 80px 70px;
  box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.3);
  background: white;
  position: absolute;
  z-index: 1;
  margin-top: 100px;
  margin-left: calc(50% - 520px);
  @media screen and (max-width: 992px) {
    width: 350px;
    margin: 120px calc(50% - 175px) 0 calc(50% - 175px);
    padding: 20px;
  }

  @media screen and (max-width: 992px) {
    width: 300px;
    margin: 120px calc(50% - 150px) 0 calc(50% - 150px);
    padding: 20px;

    & h2 {
      font-size: ${props => props.theme.font.size.l};
    }
  }
`
const BoxHeading = styled.div`
  ${props => props.hasButton && `
    margin-bottom: 50px;

    @media screen and (max-width: 992px) {
      margin-bottom: 32px;
    }
  `};
`
const MainContainer = styled.div`
  height: 600px;
  width: calc(50% + 200px);

  margin-left: auto;
  @media screen and (max-width: 992px) {
    width: 100%;
  }
`

export default function Service({
  fields,
}) {
  return (
    <div>
      <BoxContainer>
        <BoxHeading hasButton={fields.link !== undefined}>
          <ParseContent content={fields.description} />
        </BoxHeading>

        {fields.link && (
          <div className="d-flex justify-content-center">
            <Button to={fields.link.url}>
              {fields.link.title}
            </Button>
          </div>
        )}
      </BoxContainer>
      <MainContainer>
        <Image
          src={fields.image}
          style={{
            height: '600px',
            objectFit: 'cover',
            width: '100%',
          }}
        />
      </MainContainer>
    </div>
  )
}
