/* eslint-disable import/no-unresolved */
import React from 'react'
import styled from 'styled-components'

import Image from 'components/shared/Image'
import ParseContent from 'components/shared/ParseContent'

const BoxContainer = styled.div`
  width: 580px;
  padding: 60px 70px 80px 70px;
  box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.3);
  background: white;
  position: absolute;
  z-index: 1;
  margin-top: -210px;
  margin-left: ${(props) => props.direction === 'left' ? 'calc(50% + 50px)' : 'calc(50% - 520px)'};

  h2 {
    font-size: ${props => props.theme.font.size['42']};

    @media screen and (max-width: 991px) {
      font-size: ${props => props.theme.font.size['30']};
    }
  }
  

  @media screen and (max-width: 991px) {
    width: 560px;
    margin: 20px calc(50% - 280px) 0 calc(50% - 280px);
    padding: 20px;
  }

  @media screen and (max-width: 576px) {
    width: 340px;
    margin: 20px calc(50% - 170px) 0 calc(50% - 170px);
    padding: 20px;
  }
`
const MainContainer = styled.div`
  height: 600px;
  width: calc(50% + 200px);

  margin-left: ${(props) => (props.direction === 'left' ? '0' : 'auto')};
  @media screen and (max-width: 992px) {
    width: 100%;
    margin-bottom: 200px;
  }
`

export default function Welcome({
  fields,
  direction = 'left',
}) {
  return (
    <div>
      <BoxContainer direction={direction}>
        <ParseContent content={fields.description} />
      </BoxContainer>
      <MainContainer direction={direction}>
        <Image
          src={fields.image}
          style={{
            height: '600px',
            objectFit: 'cover',
            width: '100%',
          }}
        />
      </MainContainer>
    </div>
  )
}
