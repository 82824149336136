/* eslint-disable import/no-unresolved */
/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { renderToString } from 'react-dom/server'
import parse, { domToReact } from 'html-react-parser'
import styled from 'styled-components'
import ButtonDefault from 'components/elements/ButtonDefault'
import CustomLink from './CustomLink'

const Content = styled.div`
  font-weight: ${props => props.theme.font.weight.s};
  font-size: ${props => props.theme.font.size.m};

  & strong, & b {
    font-weight: ${props => props.theme.font.weight.l};
  }

  & h1, & h2, & h3, & h4, & h5 {
    color: ${props => props.isLightHs ? props.theme.color.text.light : props.theme.color.text.main};
    text-align: initial;
    margin-bottom: 36px;
    font-weight: ${props => props.theme.font.weight.s};

    & strong, & b {
      font-weight: ${props => props.theme.font.weight.l};
    }

    @media (min-width: 992px) {
      font-size: ${props => props.theme.font.size.xxl};
      line-height: ${props => props.theme.font.size.xxl};
    }

    @media (max-width: 991px) {
      font-size: ${props => props.theme.font.size.xl};
      line-height: ${props => props.theme.font.size.xl};
    }
  }

  & ul {
    
    & li {
      
    }
  }

  & a {
    
  }

  & img {

  }

  & table td {
    vertical-align: top;

    @media (max-width: 991px) {
      display: block;
      width: 100% !important;
    }
  }

  /*
    WP WYSIWYG
  */
  .alignright {
    float: right;
    text-align: right;
  }

  .wp-caption {
    max-width: 100%;
  }

  .wp-caption-text {
    font-size: 12px;
    font-weight: 700;
    text-align: center;
  }
`

const capitalize = (s) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}

const convertCSSKey = key => {
  const keySplit = key.split('-')
  let newKey = ''

  keySplit.forEach((value, index) => {
    let newValue = value

    if(index > 0) {
      newValue = capitalize(value)
    }

    newKey += newValue
  })

  return newKey
}

const convertCSS = style => {
  const result = {}
  
  if(!style) {
    return result
  }
  
  const attributes = style.split(';');

  attributes.forEach(attribute => {
    const entry = attribute.split(':')

    // eslint-disable-next-line prefer-destructuring
    result[convertCSSKey(entry[0])] = entry[1]
  })

  return result
}

const findAndReplace = (content, settings) => {
  return parse(content, {
    replace: (domNode) => {
      if (domNode.name === 'a' && domNode.attribs.class === 'custom-button') {
        const attributes = domNode.attribs

        if(attributes.href.indexOf('mailto:') !== -1 || attributes.href.indexOf('tel:') !== -1) {
          return (
            <ButtonDefault isCustom>
              <a href={domNode.attribs.href}>
                {domToReact(domNode.children, {})}
              </a>
            </ButtonDefault>
          )
        }

        return (
          <ButtonDefault to={domNode.attribs.href}>
            {domToReact(domNode.children, {})}
          </ButtonDefault>
        )
      }

      if (domNode.name === 'a' && domNode.attribs.class === 'custom-button-alt') {
        const attributes = domNode.attribs

        if(attributes.href.indexOf('mailto:') !== -1 || attributes.href.indexOf('tel:') !== -1) {
          return (
            <ButtonDefault type="outline" isCustom>
              <a href={domNode.attribs.href}>
                {domToReact(domNode.children, {})}
              </a>
            </ButtonDefault>
          )
        }

        return (
          <ButtonDefault type="outline" to={domNode.attribs.href}>
            {domToReact(domNode.children, {})}
          </ButtonDefault>
        )
      }

      if (domNode.children && domNode.children.length > 0) {
        const html = renderToString(domToReact(domNode.children, {}))
        const htmlFindAndReplace = findAndReplace(html, settings)

        const attributes = domNode.attribs

        attributes.className = attributes.class

        delete attributes.class

        if (attributes.style) {
          attributes.style = convertCSS(attributes.style)
        }

        if (
          attributes.src &&
          attributes.src.indexOf(settings.sourceUrl) === -1
        ) {
          attributes.src = `${settings.sourceUrl}${attributes.src}`
        }

        if(attributes.href) {
          if(attributes.href.indexOf('/wp-content/') !== -1 && attributes.href.indexOf(settings.sourceUrl) === -1) {
            attributes.href = `${settings.sourceUrl}${attributes.href}`
          } else if (
            attributes.href.indexOf('www.') !== -1 ||
            attributes.href.indexOf('http:') !== -1 ||
            attributes.href.indexOf('https:') !== -1
          ) {
            attributes.target = '_blank'
          } else if(attributes.href.indexOf('mailto:') === -1 && attributes.href.indexOf('tel:') === -1) {
            return (
              <CustomLink to={attributes.href} {...attributes}>
                {htmlFindAndReplace}
              </CustomLink>
            )
          }
        }

        const Comp = domNode.name

        return (
          <Comp
            src={attributes.src}
            className={attributes.className}
            style={attributes.style}
            id={attributes.id}
            href={attributes.href}
            target={attributes.target}
          >
            {htmlFindAndReplace}
          </Comp>
        )
      }

      if (domNode.name === 'img') {
        const attributes = domNode.attribs

        if (domNode.attribs.src.indexOf('/wp-content/') !== -1 && domNode.attribs.src.indexOf(settings.sourceUrl) === -1) {
          attributes.src = `${settings.sourceUrl}${domNode.attribs.src}`
        }

        attributes.className = attributes.class

        delete attributes.class

        if (attributes.style) {
          attributes.style = convertCSS(attributes.style)
        }

        return (
          <img
            src={attributes.src}
            className={attributes.className}
            style={attributes.style}
            width={attributes.width}
            height={attributes.height}
            alt={attributes.alt}
          />
        )
      }

      if (domNode.name === 'a') {
        const attributes = domNode.attribs

        if (attributes.style) {
          delete attributes.style
        }

        if (
          attributes.href.indexOf('www.') !== -1 ||
          attributes.href.indexOf('http:') !== -1 ||
          attributes.href.indexOf('https:') !== -1
        ) {
          return (
            <a {...attributes} target="_blank">
              {domToReact(domNode.children, {})}
            </a>
          )
        }

        return (
          <CustomLink to={attributes.href} {...attributes}>
            {domToReact(domNode.children, {})}
          </CustomLink>
        )
      }
    },
  })
}

const ParseContent = ({ content, className, isLightHs }) => {
  const {
    site
  } = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          siteWpUrl
        }
      }
    }
  `)

  let sourceUrl = ''

  if(site && site.siteMetadata && site.siteMetadata.siteWpUrl) {
    sourceUrl = site.siteMetadata.siteWpUrl
  }

  if(!content) {
    return ''
  }

  return (
    <Content className={className} isLightHs={isLightHs}>
      {findAndReplace(content, { sourceUrl })}
    </Content>
  )
}

export default ParseContent